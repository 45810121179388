/**
 * Nike application
 * @author {Cognizant Technology Solutions}
 */
/* eslint-disable no-param-reassign */
/* eslint-disable symbol-description */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unsafe-optional-chaining */
import { OktaAuth } from '@okta/okta-auth-js';
import '@okta/okta-auth-js/polyfill';
import env from './env.json';

const _okta = Symbol('okta');
const config = {
  qa: {
    // DEV || NONPROD1 || NONPROD3 || NONPROD4 || NONPROD5 || NONPROD7 || PLAYGROUND
    issuer: env.OKTA_URL,
    authorizeUrl: `${env.OKTA_URL}/v1/authorize`,
    tokenUrl: `${env.OKTA_URL}/v1/token`
  },
  prod: {
    // PROD || DR
    issuer: env.OKTA_URL_PROD,
    authorizeUrl: `${env.OKTA_URL_PROD}/v1/authorize`,
    tokenUrl: `${env.OKTA_URL_PROD}/v1/token`
  }
};

async function httpRequestClient(method, url, args) {
  if (
    url.includes('.well-known/openid-configuration')
    || url.includes('token')
  ) {
    args.withCredentials = false;
    delete args.headers['X-Okta-User-Agent-Extended'];
  }

  if (args.data) {
    args.body = args.data;
    delete args.data;
  }

  const response = await fetch(url, { method, ...args });
  const json = await response.json();
  return { responseText: json };
}

class DPOMAuth {
  constructor({
    clientId, redirectUri, prod = false, storage = 'localStorage'
  }) {
    const environmentConfig = prod ? config.prod : config.qa;

    this[_okta] = new OktaAuth({
      clientId,
      redirectUri,
      httpRequestClient,
      issuer: environmentConfig.issuer,
      authorizeUrl: environmentConfig.authorizeUrl,
      tokenUrl: environmentConfig.tokenUrl,
      tokenManager: {
        storage,
        secure: true
      },
      cookies: {
        sameSite: true,
        secure: true
      }
    });
  }

  async initialize() {
    const searchParams = new URLSearchParams(
      window.location.search.substring(1)
    );
    if (searchParams.get('code')) {
      try {
        const { tokens } = await this[_okta].token?.parseFromUrl();
        // this[_okta].tokenManager.add('idToken', tokens?.idToken);
        // this[_okta].tokenManager.add('accessToken', tokens?.accessToken);
        this[_okta].tokenManager.setTokens(tokens);
      } catch (error) {
        console.log(`Error: ${error}`);
      }
    }
  }

  login = (authorizeOptions = {}) => {
    this[_okta].token.getWithRedirect({
      ...authorizeOptions,
      scopes: [
        'openid', 'email', 'profile', 'legacy_username', 'iam.okta.factoryaffiliations.read', 'iam.okta.factorygroups.read'
      ]
    });
  };

  logout = () => {
    this[_okta].tokenManager.clear();
  };

  getAccessToken = async () => this[_okta].tokenManager.get('accessToken');

  getIdToken = async () => this[_okta].tokenManager.get('idToken');

  isLoggedIn = async () => {
    const idToken = await this.getIdToken();
    return idToken ? this[_okta].token.verify(idToken, {}) : false;
  };

  exists = async () => this[_okta].session.exists();
}

export default DPOMAuth;
