/* @flow */
import {
  getFiltersInResponseRequestBody,
  checkForColumnData
} from '../../helpers/dataHelpers';
import poSearchFieldProperties from '../../constants/fieldProperties/poSearchFieldProperties';
import fieldMapping from '../../constants/fieldMappings/poSearchFieldMapping';
import { showForNONPROD } from '../../helpers/envServer';

export const setFieldAsiaSize = (requestBody: Object) => {
  let updatedPayload = requestBody;
  if (showForNONPROD
        && requestBody?.fields
        && requestBody.fields.includes('poLine.asianSpecificSizeIsRelevant')) {
    const fieldsData = [...requestBody.fields];
    const itemDetailsIndex = fieldsData.indexOf('poLine.asianSpecificSizeIsRelevant');
    fieldsData.splice(
      itemDetailsIndex + 1,
      0,
      'sizes.sizeProduct.kr.chestSizeCode',
      'sizes.sizeProduct.kr.waistSizeCode',
      'sizes.sizeProduct.kr.ageBySizeCode',
      'sizes.sizeProduct.cn.topSizeCode',
      'sizes.sizeProduct.cn.bottomSizeCode'
    );
    updatedPayload = {
      ...requestBody,
      fields: fieldsData.filter((item) => item !== 'poLine.asianSpecificSizeIsRelevant')
    };
  }
  return updatedPayload;
};

export const removeFieldAsiaSize = (requestBody: Object) => {
  let updatedPayload = requestBody;
  if (showForNONPROD
        && requestBody?.fields
        && requestBody.fields.includes('sizes.sizeProduct.kr.chestSizeCode')) {
    const fieldsData = [...requestBody.fields];
    const itemDetailsIndex = fieldsData.indexOf('sizes.sizeProduct.kr.chestSizeCode');
    fieldsData.splice(
      itemDetailsIndex + 1,
      0,
      'poLine.asianSpecificSizeIsRelevant'
    );
    updatedPayload = {
      ...requestBody,
      fields: fieldsData.filter(
        (field) => ![
          'sizes.sizeProduct.kr.chestSizeCode',
          'sizes.sizeProduct.kr.waistSizeCode',
          'sizes.sizeProduct.kr.ageBySizeCode',
          'sizes.sizeProduct.cn.topSizeCode',
          'sizes.sizeProduct.cn.bottomSizeCode'
        ].includes(field)
      )
    };
  }
  return updatedPayload;
};

export const setFieldSrcSysNm = (requestBody: Object) => {
  let updatedPayload = requestBody;
  if (requestBody?.fields && !requestBody?.fields.includes('srcSysNm')) {
    updatedPayload = { ...requestBody, fields: [...requestBody.fields, 'srcSysNm'] };
  }
  return updatedPayload;
};
export const getFieldsForRequest = (
  state: Object,
  selectedColumnOrderOptions: Object,
  fields: Object
) => (checkForColumnData(state, true) ? selectedColumnOrderOptions : fields);

export const getSelectedFilterValues = (filterValues: Object) => filterValues && filterValues
  .map((filter) => {
    const filterInResponse = {};
    const fieldProperties = poSearchFieldProperties[filter] || {};
    if (!fieldProperties.primary) {
      return null;
    }
    filterInResponse.primary = fieldProperties.primary;
    if (fieldProperties.secondary) {
      filterInResponse.secondary = fieldProperties.secondary;
    }
    return filterInResponse;
  });
  // .filter((element) => element);
export const getModifiedSelectedFilters = (
  filtersSelected: Object,
  filter: string,
  modifiedSelectedFilters: Object
) => {
  const modifiedSelectedFiltersNew = modifiedSelectedFilters;
  if (filtersSelected.includes(filter)) {
    if (!modifiedSelectedFilters[filter]) modifiedSelectedFiltersNew[filter] = [];
  } else {
    modifiedSelectedFiltersNew[filter] = null;
  }
};
export function getSelectedFilterKeys(
  filter: string,
  modifiedSelectedFilters: Object,
  filterDropdownValues: Object,
  searchFiltersToBeRequested: Object,
  filtersInResponse: Object
) {
  const filterRequest: { primary: string; secondary?: string; } = { primary: filter };
  if (poSearchFieldProperties[filter] && poSearchFieldProperties[filter].secondary) {
    filterRequest.secondary = poSearchFieldProperties[filter].secondary;
  }
  if (modifiedSelectedFilters[filter]) {
    if (!filterDropdownValues[filter]) {
      searchFiltersToBeRequested.push(filterRequest);
    }
    filtersInResponse.push(filterRequest);
  }
}

export const getFilterInResponseValueInPOSearch = (selectedFilterKeys: Object) => (
  selectedFilterKeys.map((filter) => {
    const filterInResponse = {};
    const fieldProperties = poSearchFieldProperties[filter] || {};
    if (!fieldProperties) {
      return null;
    }
    filterInResponse.primary = fieldProperties.primary;
    if (fieldProperties.secondary) {
      filterInResponse.secondary = fieldProperties.secondary;
    }
    return filterInResponse;
  })
    .filter((element) => element)
);

export const getFiltersInResponse = (selectedFilterOptions: Object,
  filterValuesOptByUser: Object,
  state: Object) => (selectedFilterOptions?.length
  ? filterValuesOptByUser
  : getFiltersInResponseRequestBody(state.PoSearch.selectedFilters));

export function getFilterData(filtersInResponse: Array<Object>): Array<Object> {
  return [...new Set(filtersInResponse.map(({ primary }: { primary: string }) => primary))]
    .map((primary: string) => filtersInResponse.find(
      ({ primary: p }: { primary: string }) => p === primary
    ));
}
export const setSchedulerData = (
  schedulerResponse: Object,
  savedSearchData: Object
) => {
  const schedulerData = [];
  schedulerResponse.data.objects.forEach((item) => {
    const { saveSearchID } = item;
    if (item?.executeOnce) {
      schedulerData[saveSearchID] = {
        id: item.id,
        saveSearchID: item.saveSearchID,
        scheduleID: item.scheduleID,
        startTime: item.startTime,
        state: item.state
      };
    } else {
      schedulerData[saveSearchID] = item;
    }
  });
  const newObj = savedSearchData.data.objects.map((item) => {
    const savedSearchItem = item;
    if (schedulerData[item.id]) {
      savedSearchItem.schedulerData = schedulerData[item.id];
    }
    return savedSearchItem;
  });
  return newObj;
};
const mapFuncToRequest = (row: Object) => {
  const rowData = {
    poNumber: row[fieldMapping.PURCHASE_ORDER_NUMBER],
    itemNumber: row[fieldMapping.PO_LINE_ITEM_NUMBER],
    scheduleLineItemNumber: row[fieldMapping.SCHEDULE_LINE_ITEM_NUMBER],
    srcSysNm: row[fieldMapping.SRC_SYS_NM]
  };

  return rowData;
};

export const poSireRequestBody = (selectedRows: Object) => ({
  poData: selectedRows.map((row: Object) => mapFuncToRequest(row))
});

export const sortDataAscending = (data: Object) => {
  const sortedData = {};
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      if (key === 'poLine.destinationCountryCode') {
        sortedData[key] = [...data[key]].sort((a, b) => {
          const countryCodeA = (a.match(/^[^\s]+/) || [])[0];
          const countryCodeB = (b.match(/^[^\s]+/) || [])[0];
          if (!countryCodeA || !countryCodeB) {
            throw new Error('Invalid country code format');
          }
          if (countryCodeA < countryCodeB) return -1;
          if (countryCodeA > countryCodeB) return 1;
          return 0;
        });
      } else if (key === 'poHeader.poDocTypeCode') {
        sortedData[key] = [...data[key]].sort((a, b) => {
          const alphaA = a.match(/[a-zA-Z]+/)[0];
          const alphaB = b.match(/[a-zA-Z]+/)[0];
          const numA = parseInt(a.match(/\d+/)[0], 10);
          const numB = parseInt(b.match(/\d+/)[0], 10);
          if (alphaA === alphaB) {
            return numA - numB;
          }
          if (alphaA < alphaB) return -1;
          if (alphaA > alphaB) return 1;
          return 0;
        });
      } else if (key === 'sizes.sizePo.goodsAtConsolidatorReasonCode') {
        sortedData[key] = [...data[key]].sort((a, b) => {
          const numA = parseInt((a.match(/^\d+/) || [])[0], 10);
          const numB = parseInt((b.match(/^\d+/) || [])[0], 10);
          return numA - numB;
        });
      } else {
        sortedData[key] = [...data[key]]?.sort();
      }
    } else {
      sortedData[key] = [...data[key]];
    }
  });
  return sortedData;
};
