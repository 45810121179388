/**
 * This file is used for storing the static data.
 * @exports (searchCriteriaFields, initialSearchFieldsState, searchRequestTemplate,
 *  availableFilters)
 * @author {Cognizant Technology Solution}
 */

import appConfig from '../appConfig';
import fieldMapping from './fieldMappings/poSearchFieldMapping';
import collabReportFieldMapping from './fieldMappings/collabReportFieldMapping';
import ppmReportFieldMapping from './fieldMappings/ppmReportFieldMapping';
import poChangeReportFieldMapping from './fieldMappings/poChangeReportFieldMapping';
import lineSummaryFieldMapping from './fieldMappings/lineSummaryReportFieldMapping';
import convoReportFieldMapping from './fieldMappings/conversationThreadReportFieldMapping';
import pmoConfigFieldMapping from './fieldMappings/pmoConfigSettingFieldMapping';
import messageText from './messageText';
import airFreightFieldMapping from './fieldMappings/airFreightReportFieldMapping';
import gacChangeHistoryFieldMapping from './fieldMappings/gacChangeHistoryReportFieldMapping';
import { getArrayofYears } from '../helpers/common';
import { showForAF, showForNONPROD } from '../helpers/envServer';

const purchaseOrderNumberValue = 'Purchase Order Number';
const tradingCoPONumberValue = 'Trading Co PO Number';
const createDateValue = 'Create Date';
const documentDateValue = 'Document Date';
const dateRange = 'Date Range';

export const searchCriteriaPOReport = [
  {
    id: 78, name: purchaseOrderNumberValue, isChecked: false, content: 'poReport.purchaseOrderNumber'
  },
  {
    id: 1, name: tradingCoPONumberValue, isChecked: false, content: 'poReport.trcoNumber'
  },
  {
    id: 2, name: 'GAC Overdue Indicator', isChecked: false, content: 'poReport.gacIndicator'
  },
  {
    id: 212, name: 'Request Initiated Date', isChecked: false, content: 'poReport.requestDate'
  },
  {
    id: 232, name: 'Date Approved/Rejected', isChecked: false, content: 'poReport.dateRejected'
  },
  {
    id: 3, name: 'Collaboration Type', isChecked: false, content: 'poReport.collaborationType'
  },
  {
    id: 4, name: 'Approver ID', isChecked: false, content: 'poReport.approverID'
  },
  {
    id: 5, name: 'Initiator ID', isChecked: false, content: 'poReport.initiatorID'
  },
  {
    id: 21, name: 'Initiator role', isChecked: false, content: 'poReport.initiatorrole'
  },
  {
    id: 6, name: 'Change Request #', isChecked: false, content: 'poReport.changeRequestNumber'
  },
  {
    id: 7, name: 'Change Status Code', isChecked: false, content: 'poReport.changeCode'
  },
  {
    id: 8, name: createDateValue, isChecked: false, content: 'poReport.createDate'
  },
  {
    id: 9, name: documentDateValue, isChecked: false, content: 'poReport.documentDate'
  },
  {
    id: 10, name: 'Vendor', isChecked: false, content: 'poReport.vendor'
  },
  {
    id: 11, name: 'Doc Type', isChecked: false, content: 'poReport.docType'
  },
  {
    id: 71, name: 'Shipping Type', isChecked: false, content: 'poReport.shippingType'
  }
];
export const poSearchCriteriaFields = showForNONPROD ? [
  { key: fieldMapping.VENDOR_CODE },
  { key: fieldMapping.PURCHASE_ORDER_NUMBER },
  { key: fieldMapping.PO_LINE_ITEM_NUMBER },
  { key: fieldMapping.CREATE_DATE, group: dateRange },
  { key: fieldMapping.DOCUMENT_DATE, group: dateRange },
  { key: fieldMapping.OGAC, group: dateRange },
  { key: fieldMapping.GAC, group: dateRange },
  { key: fieldMapping.MRGAC, group: dateRange },
  { key: fieldMapping.PO_ACCEPTANCE_DATE, group: dateRange },
  { key: fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE },
  { key: fieldMapping.PRODUCT_CODE },
  { key: fieldMapping.SEASON },
  { key: fieldMapping.YEAR },
  { key: fieldMapping.PMO_DEC_CODE },
  { key: fieldMapping.DIVISION },
  { key: fieldMapping.GEOGRAPHY_CODE },
  { key: fieldMapping.ORDER_REASON_CODE },
  { key: fieldMapping.MIDSOLE_CODE },
  { key: fieldMapping.OUTSOLE_CODE },
  { key: fieldMapping.TR_CO_PURCHASE_ORDER_NUMBER },
  { key: fieldMapping.STYLE_NUMBER },
  { key: fieldMapping.PURCHASE_ORG_CODE },
  { key: fieldMapping.DIVERT_FLAG }
] : [
  { key: fieldMapping.VENDOR_CODE },
  { key: fieldMapping.PURCHASE_ORDER_NUMBER },
  { key: fieldMapping.PO_LINE_ITEM_NUMBER },
  { key: fieldMapping.CREATE_DATE, group: dateRange },
  { key: fieldMapping.DOCUMENT_DATE, group: dateRange },
  { key: fieldMapping.OGAC, group: dateRange },
  { key: fieldMapping.GAC, group: dateRange },
  { key: fieldMapping.MRGAC, group: dateRange },
  { key: fieldMapping.PO_ACCEPTANCE_DATE, group: dateRange },
  { key: fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE },
  { key: fieldMapping.PRODUCT_CODE },
  { key: fieldMapping.SEASON },
  { key: fieldMapping.YEAR },
  { key: fieldMapping.PMO_DEC_CODE },
  { key: fieldMapping.DIVISION },
  { key: fieldMapping.GEOGRAPHY_CODE },
  { key: fieldMapping.ORDER_REASON_CODE },
  { key: fieldMapping.MIDSOLE_CODE },
  { key: fieldMapping.OUTSOLE_CODE },
  { key: fieldMapping.TR_CO_PURCHASE_ORDER_NUMBER },
  { key: fieldMapping.STYLE_NUMBER },
  { key: fieldMapping.PURCHASE_ORG_CODE },
  { key: fieldMapping.DIVERT_FLAG }
];
export const searchCriteriaFields = {
  [fieldMapping.VENDOR_CODE]: 'Vendor Code',
  [fieldMapping.PURCHASE_ORDER_NUMBER]: purchaseOrderNumberValue,
  [fieldMapping.PO_LINE_ITEM_NUMBER]: 'PO Line Item Number',
  'Date Range': {
    [fieldMapping.CREATE_DATE]: createDateValue,
    [fieldMapping.DOCUMENT_DATE]: documentDateValue,
    [fieldMapping.OGAC]: 'OGAC',
    [fieldMapping.GAC]: 'GAC',
    [fieldMapping.MRGAC]: 'MRGAC',
    [fieldMapping.PO_ACCEPTANCE_DATE]: 'Acceptance Date'
  },
  [fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE]: 'Doc Type',
  [fieldMapping.PRODUCT_CODE]: 'Product Code',
  // 'product.material': 'Material',
  [fieldMapping.SEASON]: 'Season',
  [fieldMapping.YEAR]: 'Season Year',
  [fieldMapping.PMO_DEC_CODE]: 'PMO/DEC Code',
  [fieldMapping.DIVISION]: 'Division',
  [fieldMapping.GEOGRAPHY_CODE]: 'Geo Code',
  [fieldMapping.TR_CO_PURCHASE_ORDER_NUMBER]: tradingCoPONumberValue,
  [fieldMapping.STYLE_NUMBER]: 'Style Number',
  [fieldMapping.PURCHASE_ORG_CODE]: 'Purchase Org',
  [fieldMapping.DIVERT_FLAG]: 'Convert/Divert Indicator'
};

export const initialSearchFieldsState = {
  [fieldMapping.PO_LINE_ITEM_STATUS]: '',
  [fieldMapping.PMO_DEC_CODE]: [],
  [fieldMapping.VENDOR_CODE]: [],
  [fieldMapping.PURCHASE_ORDER_NUMBER]: [],
  [fieldMapping.PO_LINE_ITEM_NUMBER]: [],
  [fieldMapping.PRODUCT_CODE]: '',
  // 'product.material': '',
  [fieldMapping.TR_CO_PURCHASE_ORDER_NUMBER]: [],
  [fieldMapping.SEASON]: [],
  [fieldMapping.CREATE_DATE]: {},
  [fieldMapping.DOCUMENT_DATE]: {},
  [fieldMapping.OGAC]: {},
  [fieldMapping.GAC]: {},
  [fieldMapping.MRGAC]: {},
  [fieldMapping.PO_ACCEPTANCE_DATE]: {},
  [fieldMapping.STYLE_NUMBER]: '',
  [fieldMapping.PURCHASE_ORG_CODE]: [],
  [fieldMapping.YEAR]: [],
  [fieldMapping.DIVISION]: [],
  [fieldMapping.GEOGRAPHY_CODE]: '',
  [fieldMapping.ORDER_REASON_CODE]: '',
  [fieldMapping.MIDSOLE_CODE]: [],
  [fieldMapping.OUTSOLE_CODE]: [],
  [fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE]: [],
  [fieldMapping.DIVERT_FLAG]: ''
};

export const initialPOSearchFiltersState = showForNONPROD ? {
  [fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE]: [],
  [fieldMapping.FOB_MISMATCH_INDICATOR]: [],
  [fieldMapping.SIZE_MISMATCH_INDICATOR]: [],
  [fieldMapping.PLANT_CODE]: [],
  [fieldMapping.DESTINATION_COUNTRY_CODE]: [],
  [fieldMapping.CATEGORY_CODE]: [],
  [fieldMapping.PURCHASE_ORG_CODE]: [],
  [fieldMapping.PLANNING_PRIORITY_NUMBER_EDP_CODE]: null,
  [fieldMapping.GAC_REASON_CODE]: null,
  // [fieldMapping.MODE_OF_TRANSPORTATION]: null,
  [fieldMapping.LAUNCH_INDICATOR]: null,
  [fieldMapping.REJECTION_CODE]: null,
  [fieldMapping.SHIP_TO_CUSTOMER_NUMBER]: null,
  [fieldMapping.SUB_CATEGORY_CODE]: null,
  [fieldMapping.INVENTORY_SEGMENT_CODE]: null,
  [fieldMapping.POSEARCH_ASIAN_CC]: []
} : {
  [fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE]: [],
  [fieldMapping.FOB_MISMATCH_INDICATOR]: [],
  [fieldMapping.SIZE_MISMATCH_INDICATOR]: [],
  [fieldMapping.PLANT_CODE]: [],
  [fieldMapping.DESTINATION_COUNTRY_CODE]: [],
  [fieldMapping.CATEGORY_CODE]: [],
  [fieldMapping.PURCHASE_ORG_CODE]: [],
  [fieldMapping.PLANNING_PRIORITY_NUMBER_EDP_CODE]: null,
  [fieldMapping.GAC_REASON_CODE]: null,
  // [fieldMapping.MODE_OF_TRANSPORTATION]: null,
  [fieldMapping.LAUNCH_INDICATOR]: null,
  [fieldMapping.REJECTION_CODE]: null,
  [fieldMapping.SHIP_TO_CUSTOMER_NUMBER]: null,
  [fieldMapping.SUB_CATEGORY_CODE]: null,
  [fieldMapping.INVENTORY_SEGMENT_CODE]: null
  // [fieldMapping.POSEARCH_ASIAN_CC]: []
};

export const initialReportFieldsState = {
  [collabReportFieldMapping.REPORT_PO_DOC_TYPE]: [],
  [collabReportFieldMapping.REPORT_GAC_OVERDUE_INDICATOR]: '',
  [collabReportFieldMapping.REPORT_INITIATOR_ROLE]: [],
  [collabReportFieldMapping.REPORT_INITIATOR_ID]: [],
  [collabReportFieldMapping.REPORT_APPROVER_ID]: [],
  [collabReportFieldMapping.REPORT_DATE_APPROVED_OR_REJECTED]: { from: null, to: null },
  [collabReportFieldMapping.REPORT_REQUEST_INITIATED_DATE]: { from: null, to: null },
  [collabReportFieldMapping.REPORT_DOCUMENT_DATE]: { from: null, to: null },
  [collabReportFieldMapping.REPORT_CREATED_DATE]: { from: null, to: null },
  [collabReportFieldMapping.REPORT_CHANGE_REQUEST_NO]: [],
  [collabReportFieldMapping.REPORT_CHANGE_STATUS_CODE]: '',
  [collabReportFieldMapping.REPORT_COLLABORATION_TYPE]: '',
  [collabReportFieldMapping.REPORT_PO_NUMBER]: [],
  [collabReportFieldMapping.REPORT_PO_ITEM_NUMBER]: [],
  [collabReportFieldMapping.REPORT_VENDOR_CODE]: [],
  [collabReportFieldMapping.REPORT_SHIPPING_TYPE]: '',
  [collabReportFieldMapping.REPORT_TR_CO_PO_NUMBER]: [],
  [collabReportFieldMapping.REPORT_PRODUCT_CODE]: []
};

export const initialPOChangeReportFieldState = {
  [poChangeReportFieldMapping.PO_CHANGE_VENDOR_CODE]: [],
  [poChangeReportFieldMapping.PO_CHANGE_PO_NUMBER]: [],
  [poChangeReportFieldMapping.PO_CHANGE_ITEM_NUMBER]: [],
  [poChangeReportFieldMapping.PO_CHANGE_CHANGED_TYPE]: [],
  [poChangeReportFieldMapping.PO_CHANGE_DOC_TYPE_CODE]: [],
  [poChangeReportFieldMapping.PO_CHANGE_SEASON_CODE]: [],
  [poChangeReportFieldMapping.PO_CHANGE_SEASON_YEAR]: [],
  [poChangeReportFieldMapping.PO_CHANGE_CHANGE_DATE]: { from: null, to: null },
  [poChangeReportFieldMapping.PO_CHANGE_DOCUMENT_DATE]: { from: null, to: null },
  [poChangeReportFieldMapping.PO_CHANGE_GAC_DATE]: { from: null, to: null },
  [poChangeReportFieldMapping.PO_CHANGE_OGAC_DATE]: { from: null, to: null }
};

export const initialConversationThreadReportFieldState = {
  [convoReportFieldMapping.CONVO_THREAD_PO_NUMBER]: [],
  [convoReportFieldMapping.CONVO_THREAD_ITEM_NUMBER]: [],
  [convoReportFieldMapping.CONVO_THREAD_CONVO_ID]: [],
  [convoReportFieldMapping.CONVO_THREAD_CONVO_TOPIC_CODE]: [],
  [convoReportFieldMapping.CONVO_THREAD_CONVO_STATUS]: '',
  [convoReportFieldMapping.CONVO_THREAD_PO_LINE_ITEM_STATUS]: '',
  [convoReportFieldMapping.CONVO_THREAD_VENDOR_CODE]: [],
  [convoReportFieldMapping.CONVO_THREAD_GAC_DATE]: { from: null, to: null },
  [convoReportFieldMapping.CONVO_THREAD_DOCUMENT_DATE]: { from: null, to: null },
  [convoReportFieldMapping.CONVO_THREAD_CREATE_DATE]: { from: null, to: null },
  [convoReportFieldMapping.CONVO_THREAD_LAST_UPDATED_DATE]: { from: null, to: null },
  [convoReportFieldMapping.CONVO_THREAD_DOC_TYPE_CODE]: [],
  [convoReportFieldMapping.CONVO_THREAD_CREATED_BY]: [],
  [convoReportFieldMapping.CONVO_THREAD_LAST_UPDATED_BY]: []
};
export const initialAirFreightReportFieldState = showForAF ? {
  [airFreightFieldMapping.AIR_FREIGHT_PO_NUMBER]: [],
  [airFreightFieldMapping.AIR_FREIGHT_ITEM_NUMBER]: [],
  [airFreightFieldMapping.AIR_FREIGHT_TR_CO_PONUMBER]: [],
  [airFreightFieldMapping.AIR_FREIGHT_VENDOR]: [],
  [airFreightFieldMapping.AIR_FREIGHT_DIVISION_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_PRODUCT_CODE]: '',
  [airFreightFieldMapping.AIR_FREIGHT_ITEM_QTY]: '',
  [airFreightFieldMapping.AIR_FREIGHT_GAC_REASON_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_SEASON_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_SEASON_YEAR]: [],
  [airFreightFieldMapping.AIR_FREIGHT_LAUNCH]: [],
  [airFreightFieldMapping.AIR_FREIGHT_MIDSOLE_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_OUTSOLE_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_STYLE_NUMBER]: '',
  [airFreightFieldMapping.AIR_FREIGHT_CUSTOMER_PO]: [],
  [airFreightFieldMapping.AIR_FREIGHT_DOC_TYPE_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_PURCHASE_ORG_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_PURCHASE_GROUP_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_GEOGRAPHY_CODE]: '',
  [airFreightFieldMapping.AIR_FREIGHT_ORDER_REASON_CODE]: '',
  [airFreightFieldMapping.AIR_FREIGHT_PMO_DEC_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_CATEGORY_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_SUB_CATEGORY_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_COLOR_DESC]: '',
  [airFreightFieldMapping.AIR_FREIGHT_CREATED_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_DOC_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_CHANGED_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_OGAC]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_GAC]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_LAUNCH_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_CURRENT_EVENT_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_NEXT_EVENT_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_ACCEPT_PO_ACTUAL_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_FACTORY_DELIVERY_ACTUAL_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_EX_FACTORY_ACTUAL_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_MRGAC]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_CUSTOMER_SHIP_TO]: [],
  [airFreightFieldMapping.AIR_FREIGHT_DESTINATION_COUNTRY_CODE]: '',
  [airFreightFieldMapping.AIR_FREIGHT_PLANT_CODE]: ''
} : {
  [airFreightFieldMapping.AIR_FREIGHT_PO_NUMBER]: [],
  [airFreightFieldMapping.AIR_FREIGHT_ITEM_NUMBER]: [],
  [airFreightFieldMapping.AIR_FREIGHT_TR_CO_PONUMBER]: [],
  [airFreightFieldMapping.AIR_FREIGHT_VENDOR]: [],
  [airFreightFieldMapping.AIR_FREIGHT_DIVISION_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_PRODUCT_CODE]: '',
  [airFreightFieldMapping.AIR_FREIGHT_ITEM_QTY]: '',
  [airFreightFieldMapping.AIR_FREIGHT_GAC_REASON_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_SEASON_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_SEASON_YEAR]: [],
  [airFreightFieldMapping.AIR_FREIGHT_LAUNCH]: [],
  [airFreightFieldMapping.AIR_FREIGHT_MIDSOLE_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_OUTSOLE_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_STYLE_NUMBER]: '',
  [airFreightFieldMapping.AIR_FREIGHT_CURRENT_EVENT]: '',
  [airFreightFieldMapping.AIR_FREIGHT_NEXT_EVENT]: '',
  [airFreightFieldMapping.AIR_FREIGHT_CUSTOMER_PO]: [],
  [airFreightFieldMapping.AIR_FREIGHT_DOC_TYPE_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_PURCHASE_ORG_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_PURCHASE_GROUP_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_GEOGRAPHY_CODE]: '',
  [airFreightFieldMapping.AIR_FREIGHT_ORDER_REASON_CODE]: '',
  [airFreightFieldMapping.AIR_FREIGHT_PMO_DEC_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_CATEGORY_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_SUB_CATEGORY_CODE]: [],
  [airFreightFieldMapping.AIR_FREIGHT_COLOR_DESC]: '',
  [airFreightFieldMapping.AIR_FREIGHT_CREATED_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_DOC_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_CHANGED_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_OGAC]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_GAC]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_LAUNCH_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_CURRENT_EVENT_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_NEXT_EVENT_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_ACCEPT_PO_ACTUAL_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_FACTORY_DELIVERY_ACTUAL_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_EX_FACTORY_ACTUAL_DATE]: { from: null, to: null },
  [airFreightFieldMapping.AIR_FREIGHT_MRGAC]: { from: null, to: null }
};

export const initialGACChangeHistoryReportFieldState = {
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PO_NUMBER]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ITEM_NUMBER]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_TR_CO_PONUMBER]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_VENDOR]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_DIVISION_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PRODUCT_CODE]: '',
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ITEM_QTY]: '',
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_GAC_REASON_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_SEASON_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_SEASON_YEAR]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_LAUNCH]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_MIDSOLE_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_OUTSOLE_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_STYLE_NUMBER]: '',
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CURRENT_EVENT]: '',
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_NEXT_EVENT]: '',
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CUSTOMER_PO]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_DOC_TYPE_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PURCHASE_ORG_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PURCHASE_GROUP_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_GEOGRAPHY_CODE]: '',
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ORDER_REASON_CODE]: '',
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PMO_DEC_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CATEGORY_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_SUB_CATEGORY_CODE]: [],
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_COLOR_DESC]: '',
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CREATED_DATE]: { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_DOC_DATE]: { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CHANGED_DATE]: { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_OGAC]: { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_GAC]: { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_LAUNCH_DATE]: { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CURRENT_EVENT_DATE]: { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_NEXT_EVENT_DATE]: { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ACCEPT_PO_ACTUAL_DATE]: { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_FACTORY_DELIVERY_ACTUAL_DATE]:
    { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_EX_FACTORY_ACTUAL_DATE]:
    { from: null, to: null },
  [gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_MRGAC]: { from: null, to: null }
};
export const initialPPMReportFieldsState = showForNONPROD ? {
  [ppmReportFieldMapping.PPM_REPORT_PONUMBER]: [],
  [ppmReportFieldMapping.PPM_REPORT_LINE_NUMBER]: [],
  [ppmReportFieldMapping.PPM_REPORT_TR_CO_PONUMBER]: [],
  [ppmReportFieldMapping.PPM_REPORT_VENDOR]: [],
  [ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_DIVISION_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_DOC_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_CHANGED_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_MRGAC]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_OGAC]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_GAC]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_CREATED_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_ACTUAL_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_SEASON_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR]: [],
  [ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_DIRECT_SALES_ORDER_NUMBER]: '',
  [ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE]: '',
  [ppmReportFieldMapping.PPM_REPORT_CATEGORY_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_MIDSOLE_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_OUTSOLE_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_PURCHASE_GROUP_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_GEOGRAPHY_CODE]: '',
  [ppmReportFieldMapping.PPM_REPORT_ORDER_REASON_CODE]: '',
  [ppmReportFieldMapping.PPM_REPORT_DIVERT_FLAG]: ''
} : {
  [ppmReportFieldMapping.PPM_REPORT_PONUMBER]: [],
  [ppmReportFieldMapping.PPM_REPORT_LINE_NUMBER]: [],
  [ppmReportFieldMapping.PPM_REPORT_TR_CO_PONUMBER]: [],
  [ppmReportFieldMapping.PPM_REPORT_VENDOR]: [],
  [ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_DIVISION_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_DOC_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_CHANGED_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_MRGAC]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_OGAC]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_GAC]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_CREATED_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_ACTUAL_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT_DATE]: { from: null, to: null },
  [ppmReportFieldMapping.PPM_REPORT_SEASON_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR]: [],
  [ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_DIRECT_SALES_ORDER_NUMBER]: '',
  [ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE]: '',
  [ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT]: '',
  [ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT]: '',
  [ppmReportFieldMapping.PPM_REPORT_CATEGORY_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_MIDSOLE_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_OUTSOLE_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_PURCHASE_GROUP_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_CODE]: [],
  [ppmReportFieldMapping.PPM_REPORT_GEOGRAPHY_CODE]: '',
  [ppmReportFieldMapping.PPM_REPORT_ORDER_REASON_CODE]: '',
  [ppmReportFieldMapping.PPM_REPORT_DIVERT_FLAG]: ''
};

export const initialLineSummaryFieldsState = showForNONPROD ? {
  [lineSummaryFieldMapping.LINE_SUMMARY_PONUMBER]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_LINE_NUMBER]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_TR_CO_PONUMBER]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_VENDOR]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_DIVISION_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_PRODUCT_CODE]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_GAC_REASON_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_SEASON_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_SEASON_YEAR]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_LAUNCH]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_MIDSOLE_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_OUTSOLE_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_STYLE_NUMBER]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_CUSTOMER_PO]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_DOC_TYPE_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_PURCHASE_ORG_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_PURCHASE_GROUP_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_GEOGRAPHY_CODE]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_ORDER_REASON_CODE]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_PMO_DEC_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_CATEGORY_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_SUB_CATEGORY_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_COLOR_DESC]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_CREATED_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_DOC_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_CHANGED_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_OGAC]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_GAC]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_LAUNCH_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_ACCEPT_PO_ACTUAL_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_FACTORY_DELIVERY_ACTUAL_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_EX_FACTORY_ACTUAL_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_MRGAC]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_DIVERT_FLAG]: ''
} : {
  [lineSummaryFieldMapping.LINE_SUMMARY_PONUMBER]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_LINE_NUMBER]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_TR_CO_PONUMBER]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_VENDOR]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_DIVISION_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_PRODUCT_CODE]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_ITEM_QTY]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_GAC_REASON_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_SEASON_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_SEASON_YEAR]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_LAUNCH]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_MIDSOLE_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_OUTSOLE_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_STYLE_NUMBER]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_CURRENT_EVENT]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_NEXT_EVENT]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_CUSTOMER_PO]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_DOC_TYPE_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_PURCHASE_ORG_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_PURCHASE_GROUP_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_GEOGRAPHY_CODE]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_ORDER_REASON_CODE]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_PMO_DEC_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_CATEGORY_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_SUB_CATEGORY_CODE]: [],
  [lineSummaryFieldMapping.LINE_SUMMARY_COLOR_DESC]: '',
  [lineSummaryFieldMapping.LINE_SUMMARY_CREATED_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_DOC_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_CHANGED_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_OGAC]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_GAC]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_LAUNCH_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_CURRENT_EVENT_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_NEXT_EVENT_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_ACCEPT_PO_ACTUAL_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_FACTORY_DELIVERY_ACTUAL_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_EX_FACTORY_ACTUAL_DATE]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_MRGAC]: { from: null, to: null },
  [lineSummaryFieldMapping.LINE_SUMMARY_DIVERT_FLAG]: ''
};

export const initialSireSearchFields = {
  shipToID: '',
  plantID: '',
  ttmi: '',
  division: '',
  buyGroup: '',
  mode: '',
  'originCountry.code': '',
  'destCountry.code': ''
};

export const initialAirFreightAdjustmentSearchFields = {
  [airFreightFieldMapping.AIR_FREIGHT_ADJUSTMENT_PONUMBER]: [],
  [airFreightFieldMapping.AIR_FREIGHT_ADJUSTMENT_POITEMNUMBER]: [],
  [airFreightFieldMapping.AIR_FREIGHT_ADJUSTMENT_REQUESTOR_EMAIL]: '',
  [airFreightFieldMapping.AIR_FREIGHT_ADJUSTMENT_GEO_CODE]: '',
  [airFreightFieldMapping.AIR_FREIGHT_ADJUSTMENT_PMO]: [],
  [airFreightFieldMapping.AIR_FREIGHT_ADJUSTMENT_REQUEST_DATE]: { from: null, to: null }
};

export const initialConversationSearchFields = {
  'collaborationTopic.code': [],
  lastConversationDate: {
    from: null,
    to: null
  },
  collaborationStatus: '',
  vendorCodes: [],
  collaborationId: [],
  poNumbers: [],
  itemNumbers: []
};

export const initialPMOConfigSettingFieldState = {
  [pmoConfigFieldMapping.PMO_CONFIG_FACTORY_CODE]: '',
  [pmoConfigFieldMapping.PMO_CONFIG_FACTORY_NAME]: '',
  [pmoConfigFieldMapping.PMO_CONFIG_USER_EMAIL]: '',
  [pmoConfigFieldMapping.PMO_CONFIG_USER_ID]: '',
  [pmoConfigFieldMapping.PMO_CONFIG_USER_NAME]: '',
  [pmoConfigFieldMapping.PMO_CONFIG_OFFICE_CODE]: '',
  [pmoConfigFieldMapping.PMO_CONFIG_OFFICE_NAME]: ''
};

export const initialFieldValue = {
  'poReport.requestDate': {
    from: null,
    to: null
  },
  'poReport.dateRejected': {
    from: null,
    to: null
  },
  'poReport.createDate': {
    from: null,
    to: null
  },
  'poReport.documentDate': {
    from: null,
    to: null
  }
};

export const searchRequestTemplate = {
  fields: [],
  search: [],
  filter: [],
  // sort: {
  //   fieldName: 'poHeader.poNumber',
  //   sortOrder: 'Dsc'
  // },
  offset: '0',
  count: appConfig.SearchThresholdLimit,
  filtersInResponse: ['poHeader.poNumber']
};

export const availableFilters = showForNONPROD ? [
  fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE,
  fieldMapping.PURCHASE_ORG_CODE,
  fieldMapping.DESTINATION_COUNTRY_NAME,
  fieldMapping.GEOGRAPHY_CODE,
  fieldMapping.PLANT_CODE,
  fieldMapping.MODE_OF_TRANSPORTATION,
  fieldMapping.CATEGORY_CODE,
  fieldMapping.FOB_MISMATCH_INDICATOR,
  fieldMapping.SIZE_MISMATCH_INDICATOR,
  fieldMapping.GAC_REASON_CODE,
  fieldMapping.LAUNCH_INDICATOR,
  fieldMapping.SUB_CATEGORY_CODE,
  fieldMapping.OUTSOLE_CODE,
  // 'product.primaryPlatformDescription',
  // 'product.mrpController',
  // 'planning.planningPriorityCode',
  // 'planning.planningPriorityNumber',
  fieldMapping.SHIP_TO_CUSTOMER_NUMBER,
  fieldMapping.INVENTORY_SEGMENT_CODE,
  fieldMapping.REJECTION_CODE,
  fieldMapping.POSEARCH_ASIAN_CC
  // 'gacLock'
] : [
  fieldMapping.PURCHASING_DOCUMENT_TYPE_CODE,
  fieldMapping.PURCHASE_ORG_CODE,
  fieldMapping.DESTINATION_COUNTRY_NAME,
  fieldMapping.GEOGRAPHY_CODE,
  fieldMapping.PLANT_CODE,
  fieldMapping.MODE_OF_TRANSPORTATION,
  fieldMapping.CATEGORY_CODE,
  fieldMapping.FOB_MISMATCH_INDICATOR,
  fieldMapping.SIZE_MISMATCH_INDICATOR,
  fieldMapping.GAC_REASON_CODE,
  fieldMapping.LAUNCH_INDICATOR,
  fieldMapping.SUB_CATEGORY_CODE,
  fieldMapping.OUTSOLE_CODE,
  // 'product.primaryPlatformDescription',
  // 'product.mrpController',
  // 'planning.planningPriorityCode',
  // 'planning.planningPriorityNumber',
  fieldMapping.SHIP_TO_CUSTOMER_NUMBER,
  fieldMapping.INVENTORY_SEGMENT_CODE,
  fieldMapping.REJECTION_CODE
  // fieldMapping.POSEARCH_ASIAN_CC
  // 'gacLock'
];

export const availableReportFilters = [
  collabReportFieldMapping.REPORT_GAC_OVERDUE_INDICATOR,
  collabReportFieldMapping.REPORT_GAC_REASON_CODE,
  collabReportFieldMapping.REPORT_VENDOR_CODE
];

export const poReportFieldList = [
  { key: collabReportFieldMapping.REPORT_PO_DOC_TYPE },
  { key: collabReportFieldMapping.REPORT_DATE_APPROVED_OR_REJECTED, group: dateRange },
  { key: collabReportFieldMapping.REPORT_REQUEST_INITIATED_DATE, group: dateRange },
  { key: collabReportFieldMapping.REPORT_DOCUMENT_DATE, group: dateRange },
  { key: collabReportFieldMapping.REPORT_CREATED_DATE, group: dateRange },
  { key: collabReportFieldMapping.REPORT_CHANGE_STATUS_CODE },
  { key: collabReportFieldMapping.REPORT_COLLABORATION_TYPE },
  { key: collabReportFieldMapping.REPORT_PO_NUMBER },
  { key: collabReportFieldMapping.REPORT_PO_ITEM_NUMBER },
  { key: collabReportFieldMapping.REPORT_VENDOR_CODE },
  { key: collabReportFieldMapping.REPORT_TR_CO_PO_NUMBER },
  { key: collabReportFieldMapping.REPORT_PRODUCT_CODE }
];

export const poChangeReportFieldList = [
  { key: poChangeReportFieldMapping.PO_CHANGE_VENDOR_CODE },
  { key: poChangeReportFieldMapping.PO_CHANGE_PO_NUMBER },
  { key: poChangeReportFieldMapping.PO_CHANGE_ITEM_NUMBER },
  { key: poChangeReportFieldMapping.PO_CHANGE_CHANGED_TYPE },
  { key: poChangeReportFieldMapping.PO_CHANGE_DOC_TYPE_CODE },
  { key: poChangeReportFieldMapping.PO_CHANGE_SEASON_CODE },
  { key: poChangeReportFieldMapping.PO_CHANGE_SEASON_YEAR },
  { key: poChangeReportFieldMapping.PO_CHANGE_CHANGE_DATE, group: dateRange },
  { key: poChangeReportFieldMapping.PO_CHANGE_DOCUMENT_DATE, group: dateRange },
  { key: poChangeReportFieldMapping.PO_CHANGE_GAC_DATE, group: dateRange },
  { key: poChangeReportFieldMapping.PO_CHANGE_OGAC_DATE, group: dateRange }
];

export const ppmReportFieldList = showForNONPROD ? [
  { key: ppmReportFieldMapping.PPM_REPORT_PONUMBER },
  { key: ppmReportFieldMapping.PPM_REPORT_LINE_NUMBER },
  { key: ppmReportFieldMapping.PPM_REPORT_TR_CO_PONUMBER },
  { key: ppmReportFieldMapping.PPM_REPORT_VENDOR },
  { key: ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DIVISION_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DOC_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_CHANGED_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_MRGAC, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_OGAC, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_GAC, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_CREATED_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_ACTUAL_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_SEASON_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR },
  { key: ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DIRECT_SALES_ORDER_NUMBER },
  { key: ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_CATEGORY_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_MIDSOLE_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_OUTSOLE_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_PURCHASE_GROUP_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_GEOGRAPHY_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_ORDER_REASON_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DIVERT_FLAG }
] : [
  { key: ppmReportFieldMapping.PPM_REPORT_PONUMBER },
  { key: ppmReportFieldMapping.PPM_REPORT_LINE_NUMBER },
  { key: ppmReportFieldMapping.PPM_REPORT_TR_CO_PONUMBER },
  { key: ppmReportFieldMapping.PPM_REPORT_VENDOR },
  { key: ppmReportFieldMapping.PPM_REPORT_PMO_DEC_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DIVISION_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DOC_TYPE_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DOC_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_CHANGED_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_MRGAC, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_OGAC, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_GAC, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_ORIGIN_RECEIPT_ACTUAL_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_CREATED_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_ACCEPT_PO_ACTUAL_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_FACTORY_DELIVERY_ACTUAL_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT_DATE, group: dateRange },
  { key: ppmReportFieldMapping.PPM_REPORT_SEASON_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_SEASON_YEAR },
  { key: ppmReportFieldMapping.PPM_REPORT_GAC_REASON_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DIRECT_SALES_ORDER_NUMBER },
  { key: ppmReportFieldMapping.PPM_REPORT_PRODUCT_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_CURRENT_EVENT },
  { key: ppmReportFieldMapping.PPM_REPORT_NEXT_EVENT },
  { key: ppmReportFieldMapping.PPM_REPORT_CATEGORY_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_MIDSOLE_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_OUTSOLE_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_PURCHASE_GROUP_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_PURCHASE_ORG_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_GEOGRAPHY_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_ORDER_REASON_CODE },
  { key: ppmReportFieldMapping.PPM_REPORT_DIVERT_FLAG }
];

export const lineSummaryReportFieldList = showForNONPROD ? [
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PONUMBER },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_LINE_NUMBER },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_TR_CO_PONUMBER },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_VENDOR },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_DIVISION_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PRODUCT_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_GAC_REASON_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_SEASON_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_SEASON_YEAR },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_LAUNCH },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_MIDSOLE_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_OUTSOLE_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_STYLE_NUMBER },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CUSTOMER_PO },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_DOC_TYPE_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PURCHASE_ORG_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PURCHASE_GROUP_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_GEOGRAPHY_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_ORDER_REASON_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PMO_DEC_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CATEGORY_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_SUB_CATEGORY_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_COLOR_DESC },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CREATED_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_DOC_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CHANGED_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_OGAC, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_GAC, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_LAUNCH_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_ACCEPT_PO_ACTUAL_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_FACTORY_DELIVERY_ACTUAL_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_EX_FACTORY_ACTUAL_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_MRGAC, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_DIVERT_FLAG }
] : [
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PONUMBER },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_LINE_NUMBER },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_TR_CO_PONUMBER },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_VENDOR },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_DIVISION_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PRODUCT_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_ITEM_QTY },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_GAC_REASON_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_SEASON_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_SEASON_YEAR },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_LAUNCH },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_MIDSOLE_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_OUTSOLE_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_STYLE_NUMBER },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CURRENT_EVENT },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_NEXT_EVENT },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CUSTOMER_PO },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_DOC_TYPE_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PURCHASE_ORG_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PURCHASE_GROUP_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_GEOGRAPHY_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_ORDER_REASON_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_PMO_DEC_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CATEGORY_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_SUB_CATEGORY_CODE },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_COLOR_DESC },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CREATED_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_DOC_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CHANGED_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_OGAC, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_GAC, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_LAUNCH_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_CURRENT_EVENT_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_NEXT_EVENT_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_ACCEPT_PO_ACTUAL_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_FACTORY_DELIVERY_ACTUAL_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_EX_FACTORY_ACTUAL_DATE, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_MRGAC, group: dateRange },
  { key: lineSummaryFieldMapping.LINE_SUMMARY_DIVERT_FLAG }
];

export const conversationThreadReportFieldList = [
  { key: convoReportFieldMapping.CONVO_THREAD_PO_NUMBER },
  { key: convoReportFieldMapping.CONVO_THREAD_ITEM_NUMBER },
  { key: convoReportFieldMapping.CONVO_THREAD_CONVO_ID },
  { key: convoReportFieldMapping.CONVO_THREAD_CONVO_TOPIC_CODE },
  { key: convoReportFieldMapping.CONVO_THREAD_CONVO_STATUS },
  { key: convoReportFieldMapping.CONVO_THREAD_PO_LINE_ITEM_STATUS },
  { key: convoReportFieldMapping.CONVO_THREAD_VENDOR_CODE },
  { key: convoReportFieldMapping.CONVO_THREAD_GAC_DATE, group: dateRange },
  { key: convoReportFieldMapping.CONVO_THREAD_DOCUMENT_DATE, group: dateRange },
  { key: convoReportFieldMapping.CONVO_THREAD_CREATE_DATE, group: dateRange },
  { key: convoReportFieldMapping.CONVO_THREAD_LAST_UPDATED_DATE, group: dateRange },
  { key: convoReportFieldMapping.CONVO_THREAD_DOC_TYPE_CODE },
  { key: convoReportFieldMapping.CONVO_THREAD_CREATED_BY },
  { key: convoReportFieldMapping.CONVO_THREAD_LAST_UPDATED_BY }
];
export const airFreightReportFieldList = showForAF ? [
  { key: airFreightFieldMapping.AIR_FREIGHT_PO_NUMBER },
  { key: airFreightFieldMapping.AIR_FREIGHT_ITEM_NUMBER },
  { key: airFreightFieldMapping.AIR_FREIGHT_TR_CO_PONUMBER },
  { key: airFreightFieldMapping.AIR_FREIGHT_VENDOR },
  { key: airFreightFieldMapping.AIR_FREIGHT_OGAC, group: dateRange },
  { key: airFreightFieldMapping.AIR_FREIGHT_GAC, group: dateRange },
  { key: airFreightFieldMapping.AIR_FREIGHT_DIVISION_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_PRODUCT_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_GAC_REASON_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_SEASON_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_SEASON_YEAR },
  { key: airFreightFieldMapping.AIR_FREIGHT_LAUNCH },
  { key: airFreightFieldMapping.AIR_FREIGHT_MIDSOLE_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_OUTSOLE_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_DOC_TYPE_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_PURCHASE_ORG_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_PURCHASE_GROUP_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_GEOGRAPHY_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_ORDER_REASON_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_PMO_DEC_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_CATEGORY_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_SUB_CATEGORY_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_DESTINATION_COUNTRY_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_PLANT_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_CUSTOMER_SHIP_TO }
] : [
  { key: airFreightFieldMapping.AIR_FREIGHT_PO_NUMBER },
  { key: airFreightFieldMapping.AIR_FREIGHT_ITEM_NUMBER },
  { key: airFreightFieldMapping.AIR_FREIGHT_TR_CO_PONUMBER },
  { key: airFreightFieldMapping.AIR_FREIGHT_VENDOR },
  { key: airFreightFieldMapping.AIR_FREIGHT_OGAC, group: dateRange },
  { key: airFreightFieldMapping.AIR_FREIGHT_GAC, group: dateRange },
  { key: airFreightFieldMapping.AIR_FREIGHT_DIVISION_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_PRODUCT_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_GAC_REASON_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_SEASON_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_SEASON_YEAR },
  { key: airFreightFieldMapping.AIR_FREIGHT_LAUNCH },
  { key: airFreightFieldMapping.AIR_FREIGHT_MIDSOLE_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_OUTSOLE_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_CURRENT_EVENT },
  { key: airFreightFieldMapping.AIR_FREIGHT_NEXT_EVENT },
  { key: airFreightFieldMapping.AIR_FREIGHT_DOC_TYPE_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_PURCHASE_ORG_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_PURCHASE_GROUP_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_GEOGRAPHY_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_ORDER_REASON_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_PMO_DEC_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_CATEGORY_CODE },
  { key: airFreightFieldMapping.AIR_FREIGHT_SUB_CATEGORY_CODE }
];

export const gacChangeHistoryReportFieldList = [
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PO_NUMBER },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ITEM_NUMBER },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_TR_CO_PONUMBER },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_VENDOR },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_DIVISION_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PRODUCT_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_GAC_REASON_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_SEASON_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_SEASON_YEAR },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_LAUNCH },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_MIDSOLE_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_OUTSOLE_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CURRENT_EVENT },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_NEXT_EVENT },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_DOC_TYPE_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PURCHASE_ORG_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PURCHASE_GROUP_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_GEOGRAPHY_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ORDER_REASON_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_PMO_DEC_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CATEGORY_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_SUB_CATEGORY_CODE },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CREATED_DATE, group: dateRange },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_DOC_DATE, group: dateRange },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CHANGED_DATE, group: dateRange },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_OGAC, group: dateRange },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_GAC, group: dateRange },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_LAUNCH_DATE, group: dateRange },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_CURRENT_EVENT_DATE, group: dateRange },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_NEXT_EVENT_DATE, group: dateRange },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_ACCEPT_PO_ACTUAL_DATE, group: dateRange },
  {
    key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_FACTORY_DELIVERY_ACTUAL_DATE,
    group: dateRange
  },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_EX_FACTORY_ACTUAL_DATE, group: dateRange },
  { key: gacChangeHistoryFieldMapping.GAC_CHANGE_HISTORY_MRGAC, group: dateRange }
];

export const pmoConfigSettingsFieldList = [
  { key: pmoConfigFieldMapping.PMO_CONFIG_FACTORY_CODE },
  { key: pmoConfigFieldMapping.PMO_CONFIG_FACTORY_NAME },
  { key: pmoConfigFieldMapping.PMO_CONFIG_USER_EMAIL },
  { key: pmoConfigFieldMapping.PMO_CONFIG_USER_ID },
  { key: pmoConfigFieldMapping.PMO_CONFIG_USER_NAME },
  { key: pmoConfigFieldMapping.PMO_CONFIG_OFFICE_CODE },
  { key: pmoConfigFieldMapping.PMO_CONFIG_OFFICE_NAME }
];
export const option = [
  { text: '1 - N - Dev Delay - DO NOT USE', value: '1' },
  { text: '2 - N - Deprioritized PO', value: '2' },
  { text: '3 - N - Logistics', value: '3' },
  { text: '4 - N - Purchasing Data Integrity', value: '4' },
  { text: '5 - N - Minimums', value: '5' },
  { text: '6 - N - Over/Manual B - DO NOT USE', value: '6' },
  { text: '7 - N - Geo Req Early Ship - DO NOT USE', value: '7' },
  { text: '8 - N - Special Use 2', value: '8' },
  { text: '9 - N - Incorrect LeadTime - DO NOT USE', value: '9' },
  { text: '10 - N - Not Buy Ready', value: '10' },
  { text: '11 - N - Over Tier 1 FG Capacity', value: '11' },
  { text: '12 - N - Over Tier 1 Sub Process Capacity', value: '12' },
  { text: '13 - N - Tier2OverCapacity', value: '13' },
  { text: '14 - N - Rpln-NoPrint Cpcty - DO NOT USE', value: '14' },
  { text: '15 - N - Rpln-No Blks Avail', value: '15' },
  { text: '16 - N - Geo Requested Hold/Divert/Adjust', value: '16' },
  { text: '17 - N - Special Use 1', value: '17' },
  { text: '18 - N - InnovateTransition - DO NOT USE', value: '18' },
  { text: '19 - N - InnovateScale - DO NOT USE', value: '19' },
  { text: '20 - N - Late Forecast - DO NOT USE', value: '20' },
  { text: '21 - N - Dev Delay', value: '21' },
  { text: '22 - N - Incorrect LeadTime', value: '22' },
  { text: '25 - N - LateCommercialize - DO NOT USE', value: '25' },
  { text: '26 - N - Design or Develop - DO NOT USE', value: '26' },
  { text: '29 - N - Peak OGAC Wk - DO NOT USE', value: '29' },
  { text: '30 - N - MBOM Incomplete', value: '30' },
  { text: '41 - F - Special Use', value: '41' },
  { text: '44 - F - MBOM Incomplete', value: '44' },
  { text: '45 - F - Production', value: '45' },
  { text: '46 - F - Logistics', value: '46' },
  { text: '47 - F - Quality', value: '47' },
  { text: '48 - F - CR - DO NOT USE', value: '48' },
  { text: '51 - N - Tooling or Mold - DO NOT USE', value: '51' },
  { text: '53 - N - GAC meets OGACWeekv', value: '53' },
  { text: '54 - F - 2nd/Specialty Cap - DO NOT USE', value: '54' },
  { text: '55 - N - RGAC Improvement', value: '55' },
  { text: '57 - N - PO Consolidation - DO NOT USE', value: '57' },
  { text: '59 - N - Supply Plan Comp - DO NOT USE', value: '59' },
  { text: '60 - N - Late Transfer', value: '60' },
  { text: '61 - F - Transfer Execution', value: '61' },
  { text: '65 - F - Backlog - DO NOT USE', value: '65' },
  { text: '83 - V - Production', value: '83' },
  { text: '84 - V - Quality', value: '84' },
  { text: '85 - V - CR - DO NOT USE', value: '85' },
  { text: '86 - N - T2FcstVolatility', value: '86' },
  { text: '87 - N - NikeTrim/Component - DO NOT USE', value: '87' },
  { text: '89 - F - FctyTrim/Component - DO NOT USE', value: '89' },
  { text: '95 - F - Early Shipment - DO NOT USE', value: '95' },
  { text: '97 - N - Force of Nature', value: '97' }
];

export const newOptionsForGACRequest = [
  { text: '1 - N - Dev Delay - DO NOT USE', value: '1' },
  { text: '2 - N - Deprioritized PO', value: '2' },
  { text: '3 - N - Logistics - T1', value: '3' },
  { text: '4 - N - Purchasing Data Integrity', value: '4' },
  { text: '5 - N - Minimums', value: '5' },
  { text: '6 - N - Over/Manual B - DO NOT USE', value: '6' },
  { text: '7 - N - Geo Req Early Ship - DO NOT USE', value: '7' },
  { text: '8 - N - Special Use 2', value: '8' },
  { text: '9 - N - Incorrect LeadTime - DO NOT USE', value: '9' },
  { text: '10 - N - Not Buy Ready', value: '10' },
  { text: '11 - N - Over Tier 1 FG Capacity', value: '11' },
  { text: '12 - N - Over Tier 1 Sub Process Capacity', value: '12' },
  { text: '13 - N - Tier2OverCapacity', value: '13' },
  { text: '14 - N - Rpln-NoPrint Cpcty - DO NOT USE', value: '14' },
  { text: '15 - N - Rpln-No Blks Avail', value: '15' },
  { text: '16 - N - Geo Requested Hold/Divert/Adjust', value: '16' },
  { text: '17 - N - Special Use 1', value: '17' },
  { text: '18 - N - InnovateTransition - DO NOT USE', value: '18' },
  { text: '19 - N - InnovateScale - DO NOT USE', value: '19' },
  { text: '20 - N - Late Forecast - DO NOT USE', value: '20' },
  { text: '21 - N - Dev Delay', value: '21' },
  { text: '22 - N - Incorrect LeadTime', value: '22' },
  { text: '25 - N - LateCommercialize - DO NOT USE', value: '25' },
  { text: '26 - N - Design or Develop - DO NOT USE', value: '26' },
  { text: '29 - N - Peak OGAC Wk - DO NOT USE', value: '29' },
  { text: '30 - N - MBOM Incomplete', value: '30' },
  { text: '31 - N - Logistics - T2', value: '31' },
  { text: '32 - N - Logistics - T3T4', value: '32' },
  { text: '33 - N - ForceofNtr - T2', value: '33' },
  { text: '34 - N - ForceofNtr - T3T4', value: '34' },
  { text: '41 - F - Special Use', value: '41' },
  { text: '42 - N - Special Use 3 - T1', value: '42' },
  { text: '43 - N - Special Use 4 - T1', value: '43' },
  { text: '44 - F - MBOM Incomplete', value: '44' },
  { text: '45 - F - Production', value: '45' },
  { text: '46 - F - Logistics', value: '46' },
  { text: '47 - F - Quality', value: '47' },
  { text: '48 - F - CR - DO NOT USE', value: '48' },
  { text: '49 - N - Special Use 5 - T1', value: '49' },
  { text: '51 - N - Tooling or Mold - DO NOT USE', value: '51' },
  { text: '52 - N - Special Use 6 - T2T3T4', value: '52' },
  { text: '53 - N - GAC meets OGACWeekv', value: '53' },
  { text: '54 - F - 2nd/Specialty Cap - DO NOT USE', value: '54' },
  { text: '55 - N - RGAC Improvement', value: '55' },
  { text: '56 - N - Special Use 7 - T2T3T4', value: '56' },
  { text: '57 - N - PO Consolidation - DO NOT USE', value: '57' },
  { text: '58 - N - Special Use 8 - T2T3T4', value: '58' },
  { text: '59 - N - Supply Plan Comp - DO NOT USE', value: '59' },
  { text: '60 - N - Late Transfer', value: '60' },
  { text: '61 - F - Transfer Execution', value: '61' },
  { text: '65 - F - Backlog - DO NOT USE', value: '65' },
  { text: '83 - V - Production', value: '83' },
  { text: '84 - V - Quality', value: '84' },
  { text: '85 - V - CR - DO NOT USE', value: '85' },
  { text: '86 - N - T2FcstVolatility', value: '86' },
  { text: '87 - N - NikeTrim/Component - DO NOT USE', value: '87' },
  { text: '89 - F - FctyTrim/Component - DO NOT USE', value: '89' },
  { text: '95 - F - Early Shipment - DO NOT USE', value: '95' },
  { text: '97 - N - ForceofNtr - T1', value: '97' }
];

export const statusUpdateData = [
  { label: 'Approved', value: 'Approved' },
  { label: 'Rejected', value: 'Rejected' }
];

export const newOptionsForProd = [
  { value: '1 - N - Dev Delay - DO NOT USE', id: '1' },
  { value: '2 - N - Deprioritized PO', id: '2' },
  { value: '3 - N - Logistics - T1', id: '3' },
  { value: '4 - N - Purchasing Data Integrity', id: '4' },
  { value: '5 - N - Minimums', id: '5' },
  { value: '6 - N - Over/Manual B - DO NOT USE', id: '6' },
  { value: '7 - N - Geo Req Early Ship - DO NOT USE', id: '7' },
  { value: '8 - N - Special Use 2', id: '8' },
  { value: '9 - N - Incorrect LeadTime - DO NOT USE', id: '9' },
  { value: '10 - N - Not Buy Ready', id: '10' },
  { value: '11 - N - Over Tier 1 FG Capacity', id: '11' },
  { value: '12 - N - Over Tier 1 Sub Process Capacity', id: '12' },
  { value: '13 - N - Tier2OverCapacity', id: '13' },
  { value: '14 - N - Rpln-NoPrint Cpcty - DO NOT USE', id: '14' },
  { value: '15 - N - Rpln-No Blks Avail', id: '15' },
  { value: '16 - N - Geo Requested Hold/Divert/Adjust', id: '16' },
  { value: '17 - N - Special Use 1', id: '17' },
  { value: '18 - N - InnovateTransition - DO NOT USE', id: '18' },
  { value: '19 - N - InnovateScale - DO NOT USE', id: '19' },
  { value: '20 - N - Late Forecast - DO NOT USE', id: '20' },
  { value: '21 - N - Dev Delay', id: '21' },
  { value: '22 - N - Incorrect LeadTime', id: '22' },
  { value: '25 - N - LateCommercialize - DO NOT USE', id: '25' },
  { value: '26 - N - Design or Develop - DO NOT USE', id: '26' },
  { value: '29 - N - Peak OGAC Wk - DO NOT USE', id: '29' },
  { value: '30 - N - MBOM Incomplete', id: '30' },
  { value: '31 - N - Logistics - T2', id: '31' },
  { value: '32 - N - Logistics - T3T4', id: '32' },
  { value: '33 - N - ForceofNtr - T2', id: '33' },
  { value: '34 - N - ForceofNtr - T3T4', id: '34' },
  { value: '41 - F - Special Use', id: '41' },
  { value: '44 - F - MBOM Incomplete', id: '44' },
  { value: '45 - F - Production', id: '45' },
  { value: '46 - F - Logistics', id: '46' },
  { value: '47 - F - Quality', id: '47' },
  { value: '48 - F - CR - DO NOT USE', id: '48' },
  { value: '51 - N - Tooling or Mold - DO NOT USE', id: '51' },
  { value: '53 - N - GAC meets OGACWeekv', id: '53' },
  { value: '54 - F - 2nd/Specialty Cap - DO NOT USE', id: '54' },
  { value: '55 - N - RGAC Improvement', id: '55' },
  { value: '57 - N - PO Consolvalueation - DO NOT USE', id: '57' },
  { value: '59 - N - Supply Plan Comp - DO NOT USE', id: '59' },
  { value: '60 - N - Late Transfer', id: '60' },
  { value: '61 - F - Transfer Execution', id: '61' },
  { value: '65 - F - Backlog - DO NOT USE', id: '65' },
  { value: '83 - V - Production', id: '83' },
  { value: '84 - V - Quality', id: '84' },
  { value: '85 - V - CR - DO NOT USE', id: '85' },
  { value: '86 - N - T2FcstVolatility', id: '86' },
  { value: '87 - N - NikeTrim/Component - DO NOT USE', id: '87' },
  { value: '89 - F - FctyTrim/Component - DO NOT USE', id: '89' },
  { value: '95 - F - Early Shipment - DO NOT USE', id: '95' },
  { value: '97 - N - ForceofNtr - T1', id: '97' }
];

export const newOptions = [
  { value: '1 - N - Dev Delay - DO NOT USE', id: '1' },
  { value: '2 - N - Deprioritized PO', id: '2' },
  { value: '3 - N - Logistics - T1', id: '3' },
  { value: '4 - N - Purchasing Data Integrity', id: '4' },
  { value: '5 - N - Minimums', id: '5' },
  { value: '6 - N - Over/Manual B - DO NOT USE', id: '6' },
  { value: '7 - N - Geo Req Early Ship - DO NOT USE', id: '7' },
  { value: '8 - N - Special Use 2', id: '8' },
  { value: '9 - N - Incorrect LeadTime - DO NOT USE', id: '9' },
  { value: '10 - N - Not Buy Ready', id: '10' },
  { value: '11 - N - Over Tier 1 FG Capacity', id: '11' },
  { value: '12 - N - Over Tier 1 Sub Process Capacity', id: '12' },
  { value: '13 - N - Tier2OverCapacity', id: '13' },
  { value: '14 - N - Rpln-NoPrint Cpcty - DO NOT USE', id: '14' },
  { value: '15 - N - Rpln-No Blks Avail', id: '15' },
  { value: '16 - N - Geo Requested Hold/Divert/Adjust', id: '16' },
  { value: '17 - N - Special Use 1', id: '17' },
  { value: '18 - N - InnovateTransition - DO NOT USE', id: '18' },
  { value: '19 - N - InnovateScale - DO NOT USE', id: '19' },
  { value: '20 - N - Late Forecast - DO NOT USE', id: '20' },
  { value: '21 - N - Dev Delay', id: '21' },
  { value: '22 - N - Incorrect LeadTime', id: '22' },
  { value: '25 - N - LateCommercialize - DO NOT USE', id: '25' },
  { value: '26 - N - Design or Develop - DO NOT USE', id: '26' },
  { value: '29 - N - Peak OGAC Wk - DO NOT USE', id: '29' },
  { value: '30 - N - MBOM Incomplete', id: '30' },
  { value: '31 - N - Logistics - T2', id: '31' },
  { value: '32 - N - Logistics - T3T4', id: '32' },
  { value: '33 - N - ForceofNtr - T2', id: '33' },
  { value: '34 - N - ForceofNtr - T3T4', id: '34' },
  { value: '41 - F - Special Use', id: '41' },
  { value: '42 - N - Special Use 3 - T1', id: '42' },
  { value: '43 - N - Special Use 4 - T1', id: '43' },
  { value: '44 - F - MBOM Incomplete', id: '44' },
  { value: '45 - F - Production', id: '45' },
  { value: '46 - F - Logistics', id: '46' },
  { value: '47 - F - Quality', id: '47' },
  { value: '48 - F - CR - DO NOT USE', id: '48' },
  { value: '49 - N - Special Use 5 - T1', id: '49' },
  { value: '51 - N - Tooling or Mold - DO NOT USE', id: '51' },
  { value: '52 - N - Special Use 6 - T2T3T4', id: '52' },
  { value: '53 - N - GAC meets OGACWeekv', id: '53' },
  { value: '54 - F - 2nd/Specialty Cap - DO NOT USE', id: '54' },
  { value: '55 - N - RGAC Improvement', id: '55' },
  { value: '56 - N - Special Use 7 - T2T3T4', id: '56' },
  { value: '57 - N - PO Consolvalueation - DO NOT USE', id: '57' },
  { value: '58 - N - Special Use 8 - T2T3T4', id: '58' },
  { value: '59 - N - Supply Plan Comp - DO NOT USE', id: '59' },
  { value: '60 - N - Late Transfer', id: '60' },
  { value: '61 - F - Transfer Execution', id: '61' },
  { value: '65 - F - Backlog - DO NOT USE', id: '65' },
  { value: '83 - V - Production', id: '83' },
  { value: '84 - V - Quality', id: '84' },
  { value: '85 - V - CR - DO NOT USE', id: '85' },
  { value: '86 - N - T2FcstVolatility', id: '86' },
  { value: '87 - N - NikeTrim/Component - DO NOT USE', id: '87' },
  { value: '89 - F - FctyTrim/Component - DO NOT USE', id: '89' },
  { value: '95 - F - Early Shipment - DO NOT USE', id: '95' },
  { value: '97 - N - ForceofNtr - T1', id: '97' }
];

export const divisionOptionsForSingleSelect = [
  { value: '10', label: '10 | Apparel' },
  { value: '20', label: '20 | Footwear' },
  { value: '30', label: '30 | Accessories' }
];

export const newOptionsForSingleSelect = [
  { label: '2 - N - Deprioritized PO', value: '2' },
  { label: '3 - N - Logistics - T1', value: '3' },
  { label: '4 - N - Purchasing Data Integrity', value: '4' },
  { label: '8 - N - Special Use 2', value: '8' },
  { label: '11 - N - Over Tier 1 FG Capacity', value: '11' },
  { label: '12 - N - Over Tier 1 Sub Process Capacity', value: '12' },
  { label: '13 - N - Tier2OverCapacity', value: '13' },
  { label: '16 - N - Geo Requested Hold/Divert/Adjust', value: '16' },
  { label: '17 - N - Special Use 1', value: '17' },
  { label: '21 - N - Dev Delay', value: '21' },
  { label: '22 - N - Incorrect LeadTime', value: '22' },
  { label: '30 - N - MBOM Incomplete', value: '30' },
  { label: '31 - N - Logistics - T2', value: '31' },
  { label: '32 - N - Logistics - T3T4', value: '32' },
  { label: '33 - N - ForceofNtr - T2', value: '33' },
  { label: '34 - N - ForceofNtr - T3T4', value: '34' },
  { label: '41 - F - Special Use', value: '41' },
  { label: '44 - F - MBOM Incomplete', value: '44' },
  { label: '45 - F - Production', value: '45' },
  { label: '46 - F - Logistics', value: '46' },
  { label: '47 - F - Quality', value: '47' },
  { label: '53 - N - GAC meets OGACWeekv', value: '53' },
  { label: '55 - N - RGAC Improvement', value: '55' },
  { label: '60 - N - Late Transfer', value: '60' },
  { label: '61 - F - Transfer Execution', value: '61' },
  { label: '83 - V - Production', value: '83' },
  { label: '84 - V - Quality', value: '84' },
  { label: '86 - N - T2FcstVolatility', value: '86' },
  { label: '97 - N - ForceofNtr - T1', value: '97' }
];

export const docTypeOptions = [
  { value: '*UNK* | *UNK*', id: '*UNK*' },
  { value: 'ZP20 | NIKE Standard PO', id: 'ZP20' },
  { value: 'ZP21 | Short Lead Time PO', id: 'ZP21' },
  { value: 'ZP22 | Responsive PO', id: 'ZP22' },
  { value: 'ZP23 | Promo PO', id: 'ZP23' },
  { value: 'ZP24 | NIKE By You PO', id: 'ZP24' },
  { value: 'ZP25 | Hong Kong Digital PO', id: 'ZP25' },
  { value: 'ZP26 | Samples PO', id: 'ZP26' },
  { value: 'ZP27 | Overrun PO', id: 'ZP27' },
  { value: 'ZP28 | B Grade PO', id: 'ZP28' },
  { value: 'ZP30 | GBI TOP PO', id: 'ZP30' }
  // { value: 'ZP31 | 3rd Party Product PO', id: 'ZP31' }
];

export const docTypeOptionsForGACReport = [
  { value: '*UNK* | *UNK*', id: '*UNK*' },
  { value: 'ZP20 | NIKE Standard PO', id: 'ZP20' },
  { value: 'ZP21 | Short Lead Time PO', id: 'ZP21' },
  { value: 'ZP22 | Responsive PO', id: 'ZP22' },
  { value: 'ZP23 | Promo PO', id: 'ZP23' },
  { value: 'ZP25 | Hong Kong Digital PO', id: 'ZP25' },
  { value: 'ZP26 | Samples PO', id: 'ZP26' },
  { value: 'ZP27 | Overrun PO', id: 'ZP27' },
  { value: 'ZP28 | B Grade PO', id: 'ZP28' },
  { value: 'ZP30 | GBI TOP PO', id: 'ZP30' }
];

export const docTypeOptionsforGeo = [
  { text: 'ZP20 | NIKE Standard PO', value: 'ZP20' },
  { text: 'ZP21 | Short Lead Time PO', value: 'ZP21' },
  { text: 'ZP22 | Responsive PO', value: 'ZP22' },
  { text: 'ZP23 | Promo PO', value: 'ZP23' },
  { text: 'ZP24 | NIKE By You PO', value: 'ZP24' },
  { text: 'ZP25 | Hong Kong Digital PO', value: 'ZP25' },
  { text: 'ZP26 | Samples PO', value: 'ZP26' },
  { text: 'ZP27 | Overrun PO', value: 'ZP27' },
  { text: 'ZP28 | B Grade PO', value: 'ZP28' },
  { text: 'ZP30 | GBI TOP PO', value: 'ZP30' }
  // { text: 'ZP31 | 3rd Party Product PO', value: 'ZP31' }
];

export const poChangeTypeOptionNew = [
  { id: 'GAC Date', value: 'GAC Date' },
  { id: 'Size Active Indicator', value: 'Size Active Indicator' },
  { id: 'FOB', value: 'FOB' },
  { id: 'PO Acceptance Date', value: 'PO Acceptance Date' },
  { id: 'OGAC Date', value: 'OGAC Date' },
  { id: 'Product Name', value: 'Product Name' },
  { id: 'Line Item Active Indicator', value: 'Line Item Active Indicator' },
  { id: 'GAC Reason Code', value: 'GAC Reason Code' },
  { id: 'Customer PO Number', value: 'Customer PO Number' },
  { id: 'Mode of Transportation', value: 'Mode of Transportation' },
  { id: 'Ship To Number', value: 'Ship To Number' },
  { id: 'Purchase Group', value: 'Purchase Group' },
  { id: 'VAS Indicator', value: 'VAS Indicator' },
  { id: 'Plant', value: 'Plant' },
  { id: 'Season Year', value: 'Season Year' },
  { id: 'MCO', value: 'MCO' },
  { id: 'Season Code', value: 'Season Code' },
  { id: 'Purchase Org', value: 'Purchase Org' },
  { id: 'Material Group', value: 'Material Group' },
  { id: 'Currency', value: 'Currency' },
  { id: 'Size Quantity', value: 'Size Quantity' },
  { id: 'Size Description', value: 'Size Description' },
  { id: 'Product Code', value: 'Product Code' },
  { id: 'Doc Type', value: 'Doc Type' },
  { id: 'PO Reason Code', value: 'PO Reason Code' },
  { id: 'Item delete', value: 'Item delete' },
  { id: 'Item add', value: 'Item add' },
  { id: 'Size delete', value: 'Size delete' },
  { id: 'Item Quantity', value: 'Item Quantity' },
  { id: 'Size Added', value: 'Size Added' },
  { id: 'PO Document Entry Date', value: 'PO Document Entry Date' },
  { id: 'Vendor Code', value: 'Vendor Code' },
  { id: 'VAS', value: 'VAS' },
  { id: 'Item Text', value: 'Item Text' }
];

export const geoCodeOptions = [
  { text: 'NA', value: 'NA' },
  { text: 'EMEA', value: 'EMEA' },
  { text: 'GC', value: 'GC' },
  { text: 'APLA', value: 'APLA' }
];

export const reasonForOrderingOptions = [
  { text: '009 | Final destination UK-Pref. Import', value: '009' }
];

export const changeStatusCode = [
  {
    text: messageText.pendingLabel,
    value: 'Pending'
  },
  {
    text: messageText.sysApproved,
    value: 'System Approved'
  },
  {
    text: messageText.pmoApproved,
    value: 'Approved'
  },
  {
    text: messageText.pmoReject,
    value: 'Rejected'
  },
  {
    text: messageText.sapSuccess,
    value: 'SAP Success'
  },
  {
    text: messageText.sapFail,
    value: 'SAP Failed'
  }
];

export const collabType = [
  {
    text: messageText.gacLabel,
    value: 'GAC'
  }
];

export const ttmiOptions = [
  { text: messageText.sireTTtmiOptionB, value: 'C' },
  { text: messageText.sireTTtmiOptionC, value: 'D' },
  { text: messageText.sireTTtmiOptionD, value: 'F' },
  { text: messageText.sireTTtmiOptionE, value: 'T' },
  { text: messageText.sireTTtmiOptionNoTTMI, value: 'NoTTMI' }
];

export const buyGroupOptions = [
  { text: messageText.sireBuyGrpOptionA, value: '01' },
  { text: messageText.sireBuyGrpOptionB, value: '02' },
  { text: messageText.sireBuyGrpOptionC, value: '03' },
  { text: messageText.sireBuyGrpOptionD, value: '04' },
  { text: messageText.sireBuyGrpOptionE, value: '05' },
  { text: messageText.sireBuyGrpOptionF, value: '06' }
];

export const divisionOptions = [
  { text: messageText.sireDivOptionA, value: '10' },
  { text: messageText.sireDivOptionB, value: '20' },
  { text: messageText.sireDivOptionC, value: '30' }
];

export const modeOptions = [
  { text: messageText.sireModeOptionA, value: 'AF' },
  { text: messageText.sireModeOptionB, value: 'AS' },
  { text: messageText.sireModeOptionC, value: 'BR' },
  { text: messageText.sireModeOptionD, value: 'HC' },
  { text: messageText.sireModeOptionE, value: 'IM' },
  { text: messageText.sireModeOptionF, value: 'RL' },
  { text: messageText.sireModeOptionG, value: 'SE' },
  { text: messageText.sireModeOptionH, value: 'SP' },
  { text: messageText.sireModeOptionI, value: 'TR' },
  { text: messageText.sireModeOptionJ, value: 'VL' }
];

export const airFrieghtSubTableHeader = [
  { key: 'goodsAtConsolidatorChangeTimestamp', label: 'Change TimeStamp' },
  { key: 'previousGoodsAtConsolidatorDate', label: 'Previous GAC Date' },
  { key: 'previousGoodsAtConsolidatorReasonCode', key2: 'previousGoodsAtConsolidatorReasonDescription', label: 'Previous GAC Reason Code' },
  { key: 'goodsAtConsolidatorDate', label: 'Changed GAC Date' },
  { key: 'goodsAtConsolidatorReasonCode', key2: 'goodsAtConsolidatorReasonDescription', label: 'Changed GAC Reason Code' },
  { key: 'liability', label: 'Liability' },
  { key: 'nikeDelay', label: 'Nike Delay' },
  { key: 'factoryDelay', label: 'Factory Delay' },
  { key: 'delay', label: 'Delay' }
];

export const airFrieghtHistorySubTableHeader = [
  { key: 'afOverrideId', label: 'Request ID' },
  { key: 'proposedNAF%', label: 'Suggested NAF%' },
  { key: 'proposedFAF%', label: 'Suggested FAF%' },
  { key: 'requestedByUserID', label: 'Requestor' },
  { key: 'approverUserName', label: 'Approver' },
  { key: 'overrideStatus', label: 'Status' },
  { key: 'lastUpdatedTimestamp', label: 'Last updated timestamp' },
  { key: 'requestorComments', label: 'Requestor Comments' },
  { key: 'approverComments', label: 'Approver Comments' },
  { key: 'attachmentsCount', label: 'Attachment' }
];

export const gacChangeHistorySubTableHeader = [
  { key: 'goodsAtConsolidatorChangeTimestamp', label: 'Change TimeStamp' },
  { key: 'previousGoodsAtConsolidatorDate', label: 'Previous GAC Date' },
  { key: 'previousGoodsAtConsolidatorReasonCode', key2: 'previousGoodsAtConsolidatorReasonDescription', label: 'Previous GAC Reason Code' },
  { key: 'previousGrcUseCaseCode', key2: 'previousGrcUseCaseDescription', label: 'Previous GRC Usecase Code' },
  { key: 'goodsAtConsolidatorDate', label: 'Changed GAC Date' },
  { key: 'goodsAtConsolidatorReasonCode', key2: 'goodsAtConsolidatorReasonDescription', label: 'Changed GAC Reason Code' },
  { key: 'grcUseCaseCode', key2: 'grcUseCaseDescription', label: 'Changed GRC Usecase Code' },
  { key: 'liability', label: 'Liability' },
  { key: 'nikeDelay', label: 'Nike Delay' },
  { key: 'factoryDelay', label: 'Factory Delay' },
  { key: 'delay', label: 'Delay' }
];

export const featureFlagHistorySubTableHeader = [
  { key: 'featureName', label: 'Feature Name' },
  { key: 'status', label: 'Status' },
  { key: 'groups', label: 'Groups' },
  { key: 'factoryAffiliations', label: 'FactoryAffiliations', primary: 'factoryAffiliations' },
  { key: 'startTime', label: 'Start Time' },
  { key: 'endTime', label: 'End Time' },
  { key: 'dependencyFeatures', label: 'Dependency' },
  { key: 'lastUpdatedBy', label: 'Last Updated By' },
  { key: 'lastUpdatedAt', label: 'Last Updated At' }
];

export const divisionCodeValue = [
  { id: '10', value: '10 | Apparel' },
  { id: '20', value: '20 | Footwear' },
  { id: '30', value: '30 | Accessories' }
];

export const userGroupValues = [
  { id: 'App.GSM.DPOM.InfoTech', value: 'App.GSM.DPOM.InfoTech' },
  { id: 'App.GSM.DPOM.InfoTech.Security', value: 'App.GSM.DPOM.InfoTech.Security' },
  { id: 'App.GSM.DPOM.GeoNA', value: 'App.GSM.DPOM.GeoNA' },
  { id: 'App.GSM.DPOM.GeoGC', value: 'App.GSM.DPOM.GeoGC' },
  { id: 'App.GSM.DPOM.GeoEMEA', value: 'App.GSM.DPOM.GeoEMEA' },
  { id: 'App.GSM.DPOM.GeoAPLA', value: 'App.GSM.DPOM.GeoAPLA' },
  { id: 'App.GSM.DPOM.WHQ', value: 'App.GSM.DPOM.WHQ' },
  { id: 'App.GSM.DPOM.GeoNA.Security', value: 'App.GSM.DPOM.GeoNA.Security' },
  { id: 'App.GSM.DPOM.GeoGC.Security', value: 'App.GSM.DPOM.GeoGC.Security' },
  { id: 'App.GSM.DPOM.GeoEMEA.Security', value: 'App.GSM.DPOM.GeoEMEA.Security' },
  { id: 'App.GSM.DPOM.GeoAPLA.Security', value: 'App.GSM.DPOM.GeoAPLA.Security' },
  { id: 'App.GSM.DPOM.BPM.Security', value: 'App.GSM.DPOM.BPM.Security' },
  { id: 'App.GSM.DPOM.PMO', value: 'App.GSM.DPOM.PMO' },
  { id: 'App.GSM.DPOM.PMO.Security', value: 'App.GSM.DPOM.PMO.Security' },
  { id: 'EXT.App.GSM.DPOM.LSP.Read', value: 'EXT.App.GSM.DPOM.LSP.Read' },
  { id: 'EXT.App.GSM.DPOM.Factory', value: 'EXT.App.GSM.DPOM.Factory' },
  { id: 'EXT.App.GSM.DPOM.Factory.Read', value: 'EXT.App.GSM.DPOM.Factory.Read' }
];

export const purchaseOrgCodeValue = [
  { id: '3100', value: '3100 | Regional Single AP' },
  { id: '3101', value: '3101 | Reg.Single Sample AP' },
  { id: '3150', value: '3150 | Regional-Multi AP' },
  { id: '3151', value: '3151 | Reg-Multi Sample AP' },
  { id: '3155', value: '3155 | Country Managed AP' },
  { id: '3200', value: '3200 | Regional Single EQ' },
  { id: '3201', value: '3201 | Equipment CMP' },
  { id: '3300', value: '3300 | Regional Single FW' },
  { id: '3301', value: '3301 | Country Managed FW' },
  { id: 'FW00', value: 'FW00 | Footwear' },
  { id: 'AP00', value: 'AP00 | Apparel' },
  { id: 'EQ00', value: 'EQ00 | Equipment' }
];

export const seasonCodeValue = [
  { id: 'FA', value: 'FA' },
  { id: 'SU', value: 'SU' },
  { id: 'HO', value: 'HO' },
  { id: 'SP', value: 'SP' }
];

export const cronIntervals = [
  { text: 'Once', value: '1', label: 'Once' },
  { text: 'Daily', value: '2', label: 'Day' },
  { text: 'Weekly', value: '3', label: 'Week' },
  { text: 'Monthly', value: '4', label: 'Month' }
];

export const weekList = [
  { text: 'Sunday', value: '1' },
  { text: 'Monday', value: '2' },
  { text: 'Tuesday', value: '3' },
  { text: 'Wednesday', value: '4' },
  { text: 'Thursday', value: '5' },
  { text: 'Friday', value: '6' },
  { text: 'Saturday', value: '7' }
];

export const divertFlagOptions = [
  { text: 'Convert', value: 'Convert' },
  { text: 'Divert', value: 'Divert' }
];

export const orderReasonCodeStaticValue = [
  { text: '009 | Final destination UK-Pref. Import', value: '009' }
];

export const seasonYearValue = getArrayofYears(2019, 2025);

export const usecaseValues = [
  {
    usecaseGacReasonCode: '2',
    usecaseGacReasonCodeDescription: 'N-Deprioritized PO',
    useCaseCode: ['2.1', '2.2', '2.3', '2.4'],
    useCaseCodeDescription: [
      'Nike directed factory to prioritize production of a different PO',
      'Late SLT demand forcing FLT PO to be deprioritized due to capacity constraint',
      'Late FLT demand forcing SLT PO to be deprioritized due to capacity constraint',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '3',
    usecaseGacReasonCodeDescription: 'N-Logistics - T1',
    useCaseCode: ['3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8', '3.9', '3.10', '3.11', '3.12', '3.13', '3.14', '3.15', '3.16', '3.17', '3.18'],
    useCaseCodeDescription: [
      'Change of transportation mode causing re-work',
      'Insufficient consolidator throughput capacity or warehouse capacity',
      'Transportation strike within Nike nominated LSP scope',
      'Border crossing delays',
      'Government licenses and exporting delays',
      'Any adjustment to GAC to avoid peak delivery over factory or logistics capacity',
      'Nike logistics (not Geo) asks the factory to hold product',
      'After production, if GAC is delayed because logistics provider has a holiday',
      'Regional customer is Late sending data label to vendor',
      'Regional customs clearance documentation',
      'Nike data missing in LSP booking system',
      'Trading company invoice or scan file lateness caused by system outage',
      'LSP transition due to allocation issues',
      'LSP system outage',
      'LSP shipment on hold',
      'OR missing for courier shipments',
      'Factory shipping capacity is limited due to long national holiday/Lebran/CNY holiday',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '4',
    usecaseGacReasonCodeDescription: 'N-PurchDataIntegrity',
    useCaseCode: ['4.1', '4.2', '4.3', '4.4', '4.5', '4.6', '4.7', '4.8', '4.9', '4.10'],
    useCaseCodeDescription: [
      'Incorrect colorway purchased - post buy correction',
      'Misrouted PO',
      'Computer communication system problem between Nike and factory/vendor',
      'Late MCO Changes',
      'PO Reinstate',
      'Any computer system outage (examples - Pegasus or Mercury)',
      'Flyknit work in progress uploading not done in time by Nike',
      'PO is not consolidated to enable efficient production due to destination or production minimum quantity',
      'OGAC misalignment with factory scheduled holiday - holiday is correct in system',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '8',
    usecaseGacReasonCodeDescription: 'N-Special Use 2',
    useCaseCode: ['8.1'],
    useCaseCodeDescription: [
      'Important and specific reason which is not covered by any other reason code for T2/T3/T4 factory'
    ]
  },
  {
    usecaseGacReasonCode: '11',
    usecaseGacReasonCodeDescription: 'N-OverTier 1 FGCpcty',
    useCaseCode: ['11.1', '11.2', '11.3', '11.4', '11.5', '11.6', '11.7', '11.8', '11.9'],
    useCaseCodeDescription: [
      'Nike refusing additional tooling request',
      'Approval of partial tool adjustment',
      'Old tooling destroyed and Late decision to approve new tooling',
      'Late add PO and the factory cannot meet OGAC date due to FG capacity',
      'Nike demand higher than agreed-upon factory ramp up plan for new models',
      'System capacity modifier incorrect allowing system to overbuy PO\'s',
      'SLT planning issues (examples: Nike overpulls the OH qty and agrees to a GAC push-out, or size curve mismatch between forecast and actual PO)',
      'Multiple models requiring same OS but different uppers causing FG tooling capacity constraint',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '12',
    usecaseGacReasonCodeDescription: 'N-OverTier1SubPcsCpc',
    useCaseCode: ['12.1', '12.2'],
    useCaseCodeDescription: [
      'Over sub-process capacity (e.g. Injection Phylon, Polyurethane, Compressed Phylon, Dual Injection, etc) causing finished good capacity constraint',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '13',
    usecaseGacReasonCodeDescription: 'N-OverTier2Capacity',
    useCaseCode: ['13.1', '13.2', '13.3', '13.4', '13.5', '13.6', '13.7', '13.8'],
    useCaseCodeDescription: [
      'Any Air MI airbag issue - demand exceeding forecast, lead time violation, late delivery, etc...',
      'Non airbag - over vendor raw materials or production capacity due to materials lead time violation',
      'Non airbag - raw materials constraint due to Nike Late add request or production shift/re-allocation',
      'Non airbag - Global raw materials shortage',
      'Non airbag - Knit Center cannot supply sufficient material based on Knit/yarn commitment, or Other Flyknit issue.',
      'Non airbag - Did not hit vendor material minimum order quantity',
      'Non airbag - RFID capacity constraint, or, RFID not available for on-time PO delivery',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '16',
    usecaseGacReasonCodeDescription: 'N-GeoRqHold/Div/Adj',
    useCaseCode: ['16.1', '16.2', '16.3', '16.4', '16.5', '16.6', '16.7', '16.8', '16.9'],
    useCaseCodeDescription: [
      'Diverted PO',
      'Nike Geo requests PO early shipment',
      'Nike Geo requests factory to delay PO shipment',
      'Build and hold Program',
      'On hold due to Nike cancellation request',
      'factory requests to ship PO early (no RGAC improvement) and Nike agrees to factory request',
      'Nike Changes the PO close to the ship date causing the factory to relabel, repack, rebook, or rework shipping documents',
      'Nike request to consolidate shipments',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '17',
    usecaseGacReasonCodeDescription: 'N-Special Use',
    useCaseCode: ['17.1'],
    useCaseCodeDescription: [
      'Important and specific reason which is not covered by any other reason code for T1 factory'
    ]
  },
  {
    usecaseGacReasonCode: '21',
    usecaseGacReasonCodeDescription: 'N-Dev Delay',
    useCaseCode: ['21.1', '21.2', '21.3', '21.4'],
    useCaseCodeDescription: [
      'After PO is accepted, late change to product design, materials, or color/graphics',
      'After PO is accepted, Late FOB confirmation or FOB mismatch',
      'After PO is accepted, Late change to IPW, season change, intro month change, event change, or line plan change',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '22',
    usecaseGacReasonCodeDescription: 'N-Incorrect Leadtime',
    useCaseCode: ['22.1', '22.2', '22.3', '22.4', '22.5'],
    useCaseCodeDescription: [
      'Incorrect lead time in the system at the time of systematic buy',
      'PO with incorrect OGAC date forcing a GAC change',
      'Insufficient or incorrect lead time for SLT PO',
      'Long lead time (LLT) material requested but not approved by Nike',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '30',
    usecaseGacReasonCodeDescription: 'N-MBOM Incomplete',
    useCaseCode: ['30.1', '30.2', '30.3'],
    useCaseCodeDescription: [
      'When PO is accepted, MBOM not ready for production, caused by Nike',
      'When PO is accepted, other product readiness issue forcing a delay (example - color swatch issue, materials testing not complete in time)',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '31',
    usecaseGacReasonCodeDescription: 'N-Logistics- T2',
    useCaseCode: ['31.1', '31.2', '31.3', '31.4'],
    useCaseCodeDescription: [
      'Finished Materials are ready but unable to ship due to force majeure',
      'Government restriction (ex. port closed, port strike, Inland Transportation constraint',
      'Insufficient consolidator throughput capacity or warehouse capacity',
      'T1 factory is unable to instruct shipping direction to T2'
    ]
  },
  {
    usecaseGacReasonCode: '32',
    usecaseGacReasonCodeDescription: 'N-Logistics- T3T4',
    useCaseCode: ['32.1', '32.2', '32.3'],
    useCaseCodeDescription: [
      'Finished Yarn / Raw materials are ready at the yarn/chemical suppliers but unable to ship due to force majeure',
      'Government restriction (ex. port closed, port strike, Inland Transportation constraint)',
      'Insufficient consolidator throughput capacity or warehouse capacity'
    ]
  },
  {
    usecaseGacReasonCode: '33',
    usecaseGacReasonCodeDescription: 'N-Force of Nature- T2',
    useCaseCode: ['33.1', '33.2', '33.3'],
    useCaseCodeDescription: [
      'Natural Disaster (examples - typhoon, flooding, tsunami, earthquake) impacting T2 on workforces /capacities / output reduction',
      'Societal Disaster (examples - port strike, political upheaval) impacting T2 on workforces /capacities / output reduction',
      'Other Disaster (examples - cyber attack, widespread disease) impacting T2 on workforces /capacities / output reduction'
    ]
  },
  {
    usecaseGacReasonCode: '34',
    usecaseGacReasonCodeDescription: 'N-Force of Nature- T3T4',
    useCaseCode: ['34.1', '34.2', '34.3'],
    useCaseCodeDescription: [
      'Natural Disaster (examples - typhoon, flooding, tsunami, earthquake) impacting T3/4 on workforces /capacities / output reduction',
      'Societal Disaster (examples - port strike, political upheaval) impacting T3/4 on workforces /capacities / output reduction',
      'Other Disaster (examples - cyber attack, widespread disease) impacting T3/4 on workforces /capacities / output reduction'
    ]
  },
  {
    usecaseGacReasonCode: '41',
    usecaseGacReasonCodeDescription: 'F-Special Use',
    useCaseCode: ['41.1'],
    useCaseCodeDescription: [
      'Important and specific reason which is not covered by any other reason code'
    ]
  },
  {
    usecaseGacReasonCode: '44',
    usecaseGacReasonCodeDescription: 'F-MBOM Incomplete',
    useCaseCode: ['41.1', '44.1', '44.2', '44.3'],
    useCaseCodeDescription: [
      'When PO is accepted, MBOM not ready for production, caused by factory',
      'When PO is accepted, Other product readiness issue forcing a delay, caused by factory',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '45',
    usecaseGacReasonCodeDescription: 'F-Production',
    useCaseCode: ['45.1', '45.2', '45.3', '45.4', '45.5', '45.6', '45.7', '45.8'],
    useCaseCodeDescription: [
      'Factory failed to meet and sustain targeted production ramp up due to higher than anticipated model complexity',
      'Any delay caused by backlog quantity from previous season flowing into current season',
      'Insufficient labor causing lower than expected throughput',
      'Actual material usage greater than planned usage causing material shortage and GAC delay',
      'Unplanned downtime of machinery',
      'Factory Corporate Responsibility (CR) related (examples strike, fire, slow down, excessive OT)',
      'Factory/Country holiday closure which was not scheduled before PO issued',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '46',
    usecaseGacReasonCodeDescription: 'F-Logistics',
    useCaseCode: ['46.1', '46.2', '46.3', '46.4', '46.5'],
    useCaseCodeDescription: [
      'Incorrect documentation preventing PO from being delivered from factory to consolidator',
      'Shipping or customs paperwork not conforming to delivery instructions, fault is factory',
      'Factory ships PO early without Nike\'s permission',
      'Factory requests Late ship due to factory logistics constraint and Nike agrees with factory request',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '47',
    usecaseGacReasonCodeDescription: 'F-Quality',
    useCaseCode: ['47.1', '47.2'],
    useCaseCodeDescription: [
      'Quality concern detected during T1 FG production, including safety or regulatory failures, causing a delivery delay',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '53',
    usecaseGacReasonCodeDescription: 'N-GAC Meets OGAC Week',
    useCaseCode: ['53.1', '53.2'],
    useCaseCodeDescription: [
      'GAC date in same business week as OGAC',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '55',
    usecaseGacReasonCodeDescription: 'N-RGAC Improvement',
    useCaseCode: ['55.1', '55.2'],
    useCaseCodeDescription: [
      'GAC date adjusted early vs OGAC to better meet RGAC',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '60',
    usecaseGacReasonCodeDescription: 'N-Late Transfer',
    useCaseCode: ['60.1', '60.2', '60.3'],
    useCaseCodeDescription: [
      'Transfer of tooling and/or Tech Pack requested by Nike sourcing too late for on-time production',
      'Late or incomplete confirmation of transfer request & CTTR release',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '61',
    usecaseGacReasonCodeDescription: 'F-Transfer Execution',
    useCaseCode: ['61.1', '61.2'],
    useCaseCodeDescription: [
      'After Nike tooling/Tech-pack transfer request made, any factory to factory collaboration issues (example - delayed shipment, incorrect date, misalignment of plans',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '83',
    usecaseGacReasonCodeDescription: 'V-Production',
    useCaseCode: ['83.1', '83.2', '83.3'],
    useCaseCodeDescription: [
      'Materials delivery to T1 factory is delayed by T2 non-quality production issues, such as insufficient workforce or issues with machinery',
      'Vendor Corporate Responsibility (CR) related (examples - strike, fire, slow down, excessive OT)',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '84',
    usecaseGacReasonCodeDescription: 'V-Quality',
    useCaseCode: ['84.1', '84.2', '84.3'],
    useCaseCodeDescription: [
      'Materials quality concern detected during T2 Materials production, causing delivery delay',
      'Materials quality concern detected during T1 FG production, causing delivery delay',
      'Other'
    ]
  },
  {
    usecaseGacReasonCode: '86',
    usecaseGacReasonCodeDescription: 'N-T2FcstVolatility',
    useCaseCode: ['86.1', '86.2'],
    useCaseCodeDescription: [
      'Late Nike forecast to vendor resulting in insufficient raw materials or greige',
      'Lead time violation resulting in raw material, greige or finished material shortage or constraint'
    ]
  },
  {
    usecaseGacReasonCode: '97',
    usecaseGacReasonCodeDescription: 'N-Force of Nature - T1',
    useCaseCode: ['97.1', '97.2', '97.3', '97.4', '97.5', '97.6'],
    useCaseCodeDescription: [
      'For T1 FG delays only',
      'Natural Disaster (examples - typhoon, flooding, tsunami, earthquake)',
      'Societal Disaster (examples - port strike, political upheaval)',
      'Transportation Disaster (examples - container shortage, vessel accident)',
      'Other Disaster (examples - cyber attack, widespread disease)',
      'Other'
    ]
  }
];
