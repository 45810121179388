/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { toggleSpinner } from '../dialogStates/dialogStatesSlice';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import { getFilteredResults, getFilterDropdownValuesFromResponse, sortDataAscendingConfig } from '../../helpers/dataHelpers';
import settingsData from '../mock/pmoDecSettingsMock';

export const name = 'pmoDecConfig';

function createInitialState() {
  return {
    filterDropdownValues: {
      // factoryCode: ['TX', 'CX']
    },
    selectedFilters: {
      factoryCode: [],
      managingOffice: [],
      // managingOfficeName: [],
      managingUserID: [],
      managingUserEmail: [],
      managingUser: []
    },
    dataId: '',
    settingsFilterId: '',
    SettingsData: [],
    pmoRequestBody: null,
    dataRows: settingsData,
    pmoConfigScreenData: null,
    unfilteredConfigScreenData: null,
    addFactoryDetailDropdownValues: {
      factoryCode: []
    },
    pmoOfficeData: null
  };
}

export const initialState = createInitialState();

function createReducers() {
  function updatePMOConfigScreen(state: Object, action: Object) {
    // UPDATE_PMO_CONFIG_SCREEN_RESULTS

    const payload = {
      data: action.payload.flattenedResponse,
      filteredResults: action.payload.filteredResults || action.payload.flattenedResponse
    };

    return {
      ...state,
      pmoConfigScreenData: payload.filteredResults,
      unfilteredConfigScreenData: payload.data,
      dataId: cryptoRand().toString().substr(2, 8)
    };
  }

  function clearPMOFilters(state: Object) {
    // CLEAR_PMO_DEC_FILTERS

    return {
      ...state,
      selectedFilters: {
        ...initialState.selectedFilters
      },
      pmoConfigScreenData: state.unfilteredConfigScreenData
    };
  }

  function updateFilteredPMOConfigScreen(state: Object, action: Object) {
    // UPDATE_PMO_DEC_FILTERED_RESULTS

    const payload = {
      data: action.payload
    };

    return {
      ...state,
      pmoConfigScreenData: payload.data,
      dataId: cryptoRand().toString().substr(2, 8)
    };
  }

  function updateFilterValues(state: Object, action: Object) {
    // ADD_PMO_DEC_FILTER_VALUES

    if (action.payload.values instanceof Array && action.payload.filter) {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [action.payload.filter]: action.payload.values
        }
      };
    }
    return { ...state };
  }

  function updateFilterDropdownValues(state: Object, action: Object) {
    // SETTINGS_UPDATE_FILTER_DROPDOWN_VALUES

    if (
      action.payload && Object.keys(action.payload).length > 0
    ) {
      return {
        ...state,
        filterDropdownValues: sortDataAscendingConfig(action.payload)
      };
    }
    return { ...state };
  }

  function updatePMORequestFilterId(state: Object) {
    // SET_SETTINGS_FILTER_ID

    return { ...state, settingsFilterId: cryptoRand().toString().substr(2, 8) };
  }

  function setFactoryCodeDropdown(state: Object, action: Object) {
    // SET_PMO_FACTORY_CODE_DROPDOWN_VALUES

    return {
      ...state,
      addFactoryDetailDropdownValues: {
        ...state.addFactoryDetailDropdownValues,
        factoryCode: action.payload
      }
    };
  }

  function setOfficeCodeDropdownValues(state: Object, action: Object) {
    // SET_PMO_OFFICE_CODE_DROPDOWN_VALUES

    return {
      ...state,
      pmoOfficeData: action.payload && action.payload.pmoVendorList
    };
  }

  return {
    updatePMOConfigScreen,
    clearPMOFilters,
    updateFilteredPMOConfigScreen,
    updateFilterValues,
    updateFilterDropdownValues,
    updatePMORequestFilterId,
    setFactoryCodeDropdown,
    setOfficeCodeDropdownValues
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  function getPMOConfigResults() {
    return createAsyncThunk(
      `${name}/getPMOConfigResults`,
      async (
        { callback },
        { getState, dispatch }
      ) => {
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'pmoContacts',
            method: 'get'
          },
          dispatch,
          getState
        )
          .then((response) => {
            const state = getState();
            const flattenedResponse = getflattenResponse(response.data);
            const { selectedFilters } = state.pmodecLookup;
            const filteredResults = getFilteredResults(flattenedResponse.objects, selectedFilters, 'pmoDec');
            dispatch(actions.updatePMOConfigScreen(
              { flattenedResponse: flattenedResponse.objects, filteredResults }
            ));
            dispatch(actions.updateFilterDropdownValues(
              getFilterDropdownValuesFromResponse(flattenedResponse.objects, [
                'factoryCode',
                'managingOffice',
                'managingUserID',
                'managingUser',
                'managingUserEmail'
              ])
            ));
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(response);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            console.log(error);
            callback(error);
          });
      }
    );
  }

  function fetchPMOConfigAddFactoryDropDownData() {
    return createAsyncThunk(
      `${name}/fetchPMOConfigAddFactoryDropDownData`,
      async (
        { callback },
        { getState, dispatch }
      ) => {
        request(
          {
            api: 'dropdownLookup',
            method: 'get'
          },
          dispatch,
          getState
        )
          .then((response) => {
            const { partnerNumber } = response.data;
            const factoryCodeList = partnerNumber.map
              ? partnerNumber.map((option) => ({
                text: option.partnerNo,
                value: option.partnerNo
              }))
              : [];
            dispatch(actions.setFactoryCodeDropdown(factoryCodeList));
            if (callback) {
              callback(response.data);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function getPMOOfficeData() {
    return createAsyncThunk(
      `${name}/getPMOOfficeData`,
      async (
        { callback },
        { getState, dispatch }
      ) => {
        request(
          {
            api: 'pmoOfficeData',
            method: 'get'
          },
          dispatch,
          getState
        )
          .then((response) => {
            dispatch(actions.setOfficeCodeDropdownValues(response.data));
            if (callback) {
              callback(response.data);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function addNewFactoryDetails() {
    return createAsyncThunk(
      `${name}/addNewFactoryDetails`,
      async (
        {
          data,
          callback
        },
        { getState, dispatch }
      ) => {
        const updatedData = data.map((row) => {
          const { value } = row.factoryCode;
          if (row.factoryCode) {
            return ({
              ...row,
              factoryCode: value
            });
          }
          return row;
        });
        const pmoRequestBody = {
          pmoData: updatedData
        };
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'pmoContacts',
            method: 'post',
            data: pmoRequestBody,
            cancellable: true
          },
          dispatch,
          getState
        )
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(response.data);
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function updatePMOEmail() {
    return createAsyncThunk(
      `${name}/updatePMOEmail`,
      async (
        {
          data,
          callback
        },
        { getState, dispatch }
      ) => {
        const updateRequestBody = {
          type: 'update',
          pmoData: data
        };
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'pmoContacts',
            method: 'put',
            data: updateRequestBody,
            cancellable: true
          },
          dispatch,
          getState
        )
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(response.data);
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function applyFilters() {
    return createAsyncThunk(
      `${name}/applyFilters`,
      async (
        { payload },
        { getState, dispatch }
      ) => {
        try {
          dispatch(actions.updateFilterValues(payload));
          const state = getState();
          const { selectedFilters, unfilteredConfigScreenData } = state.pmodecLookup;
          const filteredResults = getFilteredResults(unfilteredConfigScreenData, selectedFilters, 'pmoDec');
          dispatch(actions.updateFilteredPMOConfigScreen(filteredResults));
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  function clearFilters() {
    return createAsyncThunk(
      `${name}/clearFilters`,
      async (_, { dispatch }) => {
        dispatch(actions.clearPMOFilters());
      }
    );
  }

  return {
    getPMOConfigResults: getPMOConfigResults(),
    fetchPMOConfigAddFactoryDropDownData: fetchPMOConfigAddFactoryDropDownData(),
    getPMOOfficeData: getPMOOfficeData(),
    addNewFactoryDetails: addNewFactoryDetails(),
    updatePMOEmail: updatePMOEmail(),
    applyFilters: applyFilters(),
    clearFilters: clearFilters()
  };
}

export const extraActions = createExtraActions();

export const pmoDecConfigActions = { ...actions, ...extraActions };
export const pmoDecConfigReducer = slice.reducer;
